import * as L from 'leaflet';
import $ from 'jquery';
import { DateTime } from "luxon";
import { CoordinatesHelper } from '../helpers/CoordinatesHelper';

export class FishingGearProvider {
    constructor(connectionString) {
        this.connectionString = connectionString;
    }

    getAllAsGeoJsonByProjectId(projectId, start) {
        return new Promise((resolve, reject) => {
            $.ajax({
                url: `${this.connectionString}/api/FishingGear/GetAllAsGeoJsonByProjectId`,
                type: 'GET',
                data: {
                    projectId: projectId,
                    start: start
                },
                success: (result) => {
                    resolve(JSON.parse(result));
                },
                error: (error) => {
                    reject(error);
                }
            });
        })
    }

    createTimelineItems(data) {
        let dataBuffer = [];
        data.features.forEach(function (feature) {

            feature.properties.markerFeatures.forEach(function (markerFeature) {

                if (dataBuffer.find(x => x.id === markerFeature.properties.featureId)) {
                    return
                }

                let dateTime = DateTime.fromMillis(markerFeature.properties.time[0]);
                dataBuffer.push({
                    id: markerFeature.properties.featureId,
                    title: `Fishing Gear Marker - ${feature.properties.gearType}`,
                    content: `Fishing Gear Marker - ${feature.properties.gearType}`,
                    start: dateTime.toLocal().toRFC2822(),
                    end: dateTime.toLocal().toRFC2822(),
                    group: feature.properties.featureType,
                    type: 'box'
                });
            })
        });
        return dataBuffer;
    }

    static getMarkerOptions(fishingGearfeature, markerFeature) {
        let coords = CoordinatesHelper.latLngtoDecimalDegreesMinutesString(markerFeature.geometry.coordinates[1], markerFeature.geometry.coordinates[0])
        return {
            icon: L.divIcon({
                html:
                    `
            <svg id="Layer_1" data-name="Layer 1" xmlns = "http://www.w3.org/2000/svg" viewBox = "0 0 52.02 72">
                <defs>
                    <style>
                        .cls-1
                        {
                            fill:${fishingGearfeature.properties.hexColor};
                            stroke:#000;
                            stroke-miterlimit:10;
                            fill-rule:evenodd;
                        }
                    </style>
                </defs>
                <path class="cls-1"
                    d="M66.5,40.5l-3-11,7-9H52a1.5,1.5,0,0,0-3-.36L41.56,50.08C41,50,40.53,50,40,50a20.09,20.09,0,1,0,4.54.54l2.51-10Z"
                    transform="translate(-19.5 -18.5)"/>
            </svg>
            `,
                className: "rotated-marker",
                //shadowUrl: 'leaf-shadow.png',
                iconSize: [30, 45], // size of the icon
                //shadowSize:   [50, 64], // size of the shadow
                iconAnchor: [12, 40], // point of the icon which will correspond to marker's location
                //shadowAnchor: [4, 62],  // the same for the shadow
                popupAnchor: [0, -10] // point from which the popup should open relative to the iconAnchor
            }),
            popupContent: `<b>Fishing Gear Marker ${markerFeature.properties.featureId}</b><br>
                        <b>Description:</b> ${markerFeature.properties.description}<br> 
                        <b>Logged By:</b> <a href="https://www.marinetraffic.com/en/ais/details/ships/${markerFeature.properties.loggedByMmsi}" target="_blank">${markerFeature.properties.loggedByMmsi}</a><br>
                        <b>Date:</b> ${DateTime.fromMillis(markerFeature.properties.time[0], { zone: 'utc' }).toLocal().toLocaleString(DateTime.DATETIME_SHORT)}<br><br>
                        <b>Fishing Gear Details</b><br>
                        <b>Type:</b> ${fishingGearfeature.properties.gearType}<br>
                        <b>Vessel Name:</b> ${fishingGearfeature.properties.vesselName}<br>
                        <b>Description:</b> ${fishingGearfeature.properties.description}<br>
                        <b>Coordinates:</b>
                        <br>
                        <ul>
                            <li>
                                <b>Latitude:</b> ${coords.lat}
                            </li>
                            <li>
                                <b>Longitude:</b> ${coords.lng}
                            </li>
                        </ul>`,
            tooltipContent: `Fishing Gear Marker`
        }
    }
}