import * as L from 'leaflet';
import $ from 'jquery';
import { DateTime } from "luxon";
import { CoordinatesHelper } from '../helpers/CoordinatesHelper';

export class WildlifeProvider {
    constructor(connectionString) {
        this.connectionString = connectionString;
    }

    getAllAsGeoJsonByProjectId(projectId, start, end) {
        return new Promise((resolve, reject) => {
            $.ajax({
                url: `${this.connectionString}/api/Wildlife/GetAllAsGeoJsonByProjectIdAndRange`,
                type: 'GET',
                data: {
                    projectId: projectId,
                    start: start,
                    end: end
                },
                success: (result) => {
                    resolve(JSON.parse(result));
                },
                error: (error) => {
                    reject(error);
                }
            });
        })
    }

    
    createTimelineItems(data) {
        let dataBuffer = [];
        data.features.forEach(function (feature) {
            if (dataBuffer.find(x => x.id === feature.properties.featureId)){
                return
            }

            let dateTime = DateTime.fromMillis(feature.properties.time[0]);
            dataBuffer.push({
                id: feature.properties.featureId,
                title: feature.properties.wildlifeType,
                content: feature.properties.wildlifeType,
                start: dateTime.toLocal().toRFC2822(),
                end: dateTime.toLocal().toRFC2822(),
                group: feature.properties.featureType,
                type: 'box'
            });
        });

        return dataBuffer;
    }
    
    static getMarkerOptions(feature) {

        let coords = CoordinatesHelper.latLngtoDecimalDegreesMinutesString(feature.geometry.coordinates[1], feature.geometry.coordinates[0])

        let divIcon = ''

        switch (feature.properties.wildlifeType) {
            case 'Dolphin':
                divIcon = L.divIcon({
                    html:
                        `<svg id="Layer_1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 211.11 120.36">
                        <defs>
                            <style>
                            .cls-1{fill:#b3b3b3;stroke:#000;stroke-miterlimit:10;stroke-width:.71px;}
                            </style>
                        </defs>
                        <path class="cls-1" d="M163.6,52.36c.17-.72-.31-2.61-1.52-3.7-6.21-1.74,.56-25.09-66.34-21.67-9.43-8.09-17.57-9.7-24.53-5.39-.22,.59,.03,.94,.73,1.06,5.31,1.24,6.65,4.79,5.57,9.75-1.27,5.95-22.12,19.63-28.21,44.7-.37,3.39-.14,7.34,.44,11.64,.37,1.33-.09,2.29-.91,3.09-10.96,8.76-21.03,18.08-21.17,19.98-.14,1.9,1.02,2.06,1.02,2.06,1.66-2.33,6.24-5.55,12.1-9.17,0,0,5.53-2,6.68-2.86,1.15-.86,3.03-2.27,3.03-2.27,1.83,.32,5.55-.58,10.14-2.05,1.44-.29,3.25,1.11,3.92,.86,.66-.25,.57-2.26,.08-3.26-.86-2.16-2.47-3.59-4.73-4.35-1.55,.41-2.91,.58-3.92,.35-1.77-3.47,8.03-15.85,17.25-25.49,8.72-6.3,18.02-9.04,27.81-8.64-2.17,2-4.58,3.66-7.23,4.95-1.2,1.7,1.98,2.07,6.16,2.13,3.05-.05,6.34-.94,9.71-2.13,1.54,1.39,3.69,2.01,7.13,.98,3.56-.61,9.56-8.41,14.85-10.53,5.29-2.12,17.61,.02,29.25,1.96,1.27-.02,2.25-.2,1.73-1.29,1.06,.21,1.28-.09,.95-.71Zm-9.91-7.06c-.46,0-.83-.37-.83-.83s.37-.83,.83-.83,.83,.37,.83,.83-.37,.83-.83,.83Z"/>
                    </svg>
                `,
                    //shadowUrl: 'leaf-shadow.png',
                    className: "rotated-marker",
                    style: "transform-origin: center;",
                    iconSize: [45, 30], // size of the icon
                    //shadowSize:   [50, 64], // size of the shadow
                    iconAnchor: [20, 11], // point of the icon which will correspond to marker's location
                    //shadowAnchor: [4, 62],  // the same for the shadow
                    popupAnchor: [0, -10] // point from which the popup should open relative to the iconAnchor
                })
                break;
            case 'Bird':
                divIcon = L.divIcon({
                    html:
                        ` <svg id="Layer_1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 211.11 120.36">
                        <defs>
                            <style>
                                .cls-1{fill:#fff;stroke:#000;stroke-miterlimit:10;}
                            </style>
                        </defs>
                        <g id="_2rZBg4">
                            <path class="cls-1" d="M17.77,40.27c14.21-2.14,28.26-.13,42.21,2.61,14.08,2.77,27.06,8.32,39.47,17.33,2.93-3.43,6.81-6.43,8.56-10.37,3.1-7.01,7.82-10.58,15.27-11.45,24.35-2.85,48.55-2.76,72.49,3.21,2.18,.54,4.22,1.64,6.33,2.48-.1,.3-.2,.6-.3,.9-4.05,0-8.12,.19-12.16-.04-11.1-.62-22.22-2.27-33.29-1.96-17.28,.49-30.52,9.17-40.34,23.2-.3,.43-.5,.93-.97,1.83,5.06,2.95,10.01,5.83,14.95,8.71v.8c-3.34-.26-6.69-.52-8.69-.67-3.12,1.44-5.21,2.81-7.49,3.37-5.36,1.31-10.79,2.42-16.24,3.27-5.52,.86-10.83-.16-15.85-2.73-2.18-1.11-4.45-2.09-6.79-2.79-.84-.25-1.99,.51-3.16,.86-.14-.3-.55-.77-.44-1.02,1.08-2.5,2.84-4.86,3.25-7.44,.59-3.72,3.17-3.9,6.17-4.34-3.79-6.89-9.08-11.7-15.86-14.91-10.18-4.83-21.05-7.08-32.13-8.46-4.97-.62-9.98-.97-14.97-1.44,0-.32-.02-.63-.03-.95Z"/>
                        </g>
                    </svg>
                `,
                    //shadowUrl: 'leaf-shadow.png',
                    className: "rotated-marker",
                    style: "transform-origin: center;",
                    iconSize: [55, 30], // size of the icon
                    //shadowSize:   [50, 64], // size of the shadow
                    iconAnchor: [26, 16], // point of the icon which will correspond to marker's location
                    //shadowAnchor: [4, 62],  // the same for the shadow
                    popupAnchor: [0, -10] // point from which the popup should open relative to the iconAnchor
                })
                break;
            case 'Whale':
                divIcon = L.divIcon({
                    html:
                        ` <svg id="Layer_1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 68.96 19.6">
                        <defs>
                            <style>
                                .cls-1{fill:#647e8c;stroke:#4d4d4d;stroke-miterlimit:10;stroke-width:.71px;}
                            </style>
                        </defs>
                        <path class="cls-1" d="M67.91,5.53c-1.68-.74-3.38-1.19-5.12-.16-.72,.43-1.4,.9-2.03,1.46-.54,.48-1.15,.83-1.84,1.08-2.11,.76-3.97-.06-5.81-.91-1.95-.9-3.75-2.11-5.98-2.4-3.25-.42-6.4-1.36-9.56-2.19-1.27-.33-2.59-.57-3.88-.31-.99,.2-1.98,.29-2.85-.1-2.18-.97-4.47-1.18-6.78-1.18-2.01,0-4.02,.22-6.03,.31-1.07,.05-2.15-.07-3.2,.07-2.22,.29-4.37-.06-6.53-.43C6.55,.47,4.79,.22,3.04,.44,1.07,.69-.15,1.44,.56,3.55c.26,.76,.19,1.59,.25,2.39,.13,1.88-.46,3.77,.11,5.65,.22,.73,.62,1.1,1.3,1.07,1.27-.05,2.54-.02,3.81,.04,1.84,.1,3.67,.27,5.46,1.09-.63,.32-1.24,.39-1.85,.46-2.1,.27-4.23,.13-6.32,.5-.81,.14-1.66-.3-2.48-.31,.64,1.34,1.91,1.69,3.19,1.94,3.18,.64,6.44,.31,9.64,.89,1.64,.3,3.32,.41,4.98,.48,2.23,.1,4.47,.25,6.7-.03,3.25-.39,6.51-.75,9.79-.23,1.96,.31,3.89,.05,5.74-.55,4.14-1.36,8.33-2.5,12.68-2.99,1.6-.18,3.09-.09,4.57,.77,1.36,.8,2.4,1.94,3.64,2.86,2.21,1.65,3.99,2.33,6.5,.7-1.63-.79-2.05-1.27-2.66-3.08-.34-1.01-.55-2.08-1.39-2.85-.24-.22-.06-.42,.06-.63,.38-.66,.65-1.41,.42-2.14-.54-1.72,.94-3.44,3.02-3.3,.23,.01,.53,.13,.63-.16,.1-.31-.16-.5-.4-.61ZM15.11,7.73c-.42,0-.76-.23-.76-.52s.34-.52,.76-.52,.76,.23,.76,.52-.34,.52-.76,.52Z"/>
                    </svg>
                `,
                    //shadowUrl: 'leaf-shadow.png',
                    className: "rotated-marker",
                    style: "transform-origin: center;",
                    iconSize: [45, 20], // size of the icon
                    //shadowSize:   [50, 64], // size of the shadow
                    iconAnchor: [20, 12], // point of the icon which will correspond to marker's location
                    //shadowAnchor: [4, 62],  // the same for the shadow
                    popupAnchor: [0, -10] // point from which the popup should open relative to the iconAnchor
                })
                break;
            case 'Seal':
                divIcon = L.divIcon({
                    html:
                        `  <svg id="Layer_1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 211.11 120.36">
                        <defs>
                            <style>
                                .cls-1{fill:#333;stroke:gray;stroke-miterlimit:10;stroke-width:.28px;}
                            </style>
                        </defs>
                        <path class="cls-1" d="M185.9,78.25c-.31-.25-.65-.46-.99-.71,1.24-.69,1.25-1.19,.09-1.89-.48-.29-1.05-.48-1.12-1.18-.02-.15-.23-.28-.36-.42,.21-.1,.47-.17,.66-.32,.13-.1,.29-.36,.24-.48-.19-.46,0-.78,.3-1.08,.25-.26,.52-.5,.75-.78,.38-.46,.33-.79-.2-1.08-.41-.22-.85-.37-1.34-.58,.19-.22,.34-.39,.49-.57,.5-.63,.4-.99-.37-1.28-.16-.06-.32-.12-.49-.12-1.65-.04-3.31-.13-4.96-.08-3.72,.12-7.42,.19-11.11-.42-1.68-.28-3.28-.71-4.69-1.77-1.04-.78-2.24-1.38-3.41-1.96-3.74-1.84-7.69-3.08-11.77-3.94-3.88-.82-7.45-2.54-11.02-4.19-4.63-2.14-9.21-4.41-13.85-6.53-5.17-2.36-10.45-4.47-16.01-5.7-3.61-.8-7.28-1.34-10.94-1.91-3.84-.59-7.7-.95-11.59-.91-2.76,.03-5.52,.17-8.27,.13-2.55-.04-5.06-.41-7.47-1.35-2.09-.82-3.86-2.02-4.94-4.07-.35-.65-.79-1.26-1.21-1.87-1.39-2-2.96-3.84-5.06-5.13-2.79-1.72-5.86-2.13-9.05-1.9-2.64,.19-4.78,1.33-6.4,3.45-.3,.39-.63,.74-.96,1.11-1.01,1.12-2.23,1.87-3.71,2.2-.7,.15-1.43,.25-2.1,.48-.39,.14-.88,.45-.99,.79-.24,.75-.29,1.57-.38,2.36-.03,.25,0,.56,.12,.76,.42,.68,.7,1.5,1.54,1.83,.07,.03,.13,.14,.14,.22,.09,.75,.63,1.1,1.26,1.27,.77,.22,1.56,.39,2.36,.49,2.82,.36,4.47,1.69,5.14,4.43,.97,4,2.21,7.91,3.69,11.74,2.1,5.42,5.1,10.25,9.62,14,.9,.74,1.4,1.45,1.57,2.72,.61,4.53,3.19,7.56,7.59,8.95,3.67,1.16,7.46,1.56,11.27,1.74,1.2,.06,2.42-.01,3.62-.12,1.71-.15,2.06-.8,1.1-2.19-.75-1.09-1.73-2.02-2.61-3.02-.1-.11-.2-.22-.36-.39,.23,0,.36,0,.49,0,4.75,.12,9.5,.29,14.26,.36,8.6,.13,17.19,.24,25.79,.31,8.13,.07,16.26,0,24.37-.57,2.87-.2,5.74-.51,8.61-.84,2.08-.24,4.14-.33,6.22,0,4.1,.64,8.18,1.37,12.29,1.91,3.86,.51,7.74,.61,11.61,.09,.77-.1,1.55-.26,2.29-.49,.88-.28,.97-.94,.26-1.5ZM46.08,31.97c-.53,0-.96-.43-.96-.96s.43-.96,.96-.96,.96,.43,.96,.96-.43,.96-.96,.96Z"/>
                    </svg>
                `,
                    //shadowUrl: 'leaf-shadow.png',
                    className: "rotated-marker",
                    style: "transform-origin: center;",
                    iconSize: [45, 20], // size of the icon
                    //shadowSize:   [50, 64], // size of the shadow
                    iconAnchor: [20, 13], // point of the icon which will correspond to marker's location
                    //shadowAnchor: [4, 62],  // the same for the shadow
                    popupAnchor: [0, -10] // point from which the popup should open relative to the iconAnchor
                })
                break;
            case 'Turtle':
            default:
                divIcon = L.divIcon({
                    html:
                        `  <svg id="Layer_1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 211.11 120.36">
                        <defs>
                            <style>
                                .cls-1{stroke:#fff;stroke-miterlimit:10;stroke-width:.28px;}
                            </style>
                        </defs>
                        <path class="cls-1" d="M122.79,61.19c0,11.45-7.84,22.93-17.31,22.93s-16.98-11.48-16.98-22.93,7.68-20.74,17.14-20.74,17.14,9.29,17.14,20.74Z"/><path class="cls-1" d="M89.96,50.61c1.19-2.53,2.79-4.75,4.7-6.52,.24-.98-.36-1.74-1.84-2.03-1.42-.28-3.08-.21-4.45,.26-5.56,1.89-11.05,3.96-16.54,6.04-.62,.23-1.06,.92-1.58,1.4,.56,.45,1.05,1.1,1.69,1.32,3.6,1.25,7.19,1.16,10.62-.58,2.26-1.14,4.4-1.35,6.63,.02,.2,.12,.47,.12,.76,.09Z"/><path class="cls-1" d="M92.74,77.45c-.39-.55-.76-1.13-1.12-1.72-.09,.55-.18,1.06-.2,1.57-.22,4.01,.33,7.79,3.33,10.8,1.21,1.21,2.02,.99,2.42-.64,.37-1.55,.74-3.09,1.64-4.32-2.23-1.28-4.3-3.19-6.08-5.7Z"/><path class="cls-1" d="M118.39,77.46c-1.79,2.44-3.83,4.33-6.04,5.61,.97,1.24,1.3,2.72,1.71,4.16,.16,.56,.54,1.36,.97,1.47,.45,.11,1.22-.34,1.61-.76,3.33-3.56,3.58-7.82,2.99-12.36-.39,.65-.81,1.28-1.25,1.88Z"/><path class="cls-1" d="M139.96,48.58c-.89-.48-1.84-.86-2.8-1.2-5.16-1.81-10.33-3.64-15.51-5.38-1.43-.48-2.93-.42-4.25,.48-.67,.46-.97,.93-.94,1.44,2.04,1.85,3.75,4.2,4.98,6.9,.29-.12,.6-.24,.88-.41,1.97-1.19,3.94-1.13,5.95-.11,.54,.28,1.1,.53,1.67,.74,3.34,1.23,6.68,1.14,9.96-.2,.44-.18,1-.72,1.02-1.12,.02-.38-.53-.92-.96-1.16Z"/><path class="cls-1" d="M101.1,40.29c1.45-.46,2.98-.7,4.55-.7s2.97,.23,4.37,.65c.77-.22,.97-.97,1.37-2.88,.17-.84,.26-1.7,.36-2.44-.18-3.99-2.29-6.71-5.27-8.85-.4-.29-1.39-.24-1.8,.07-4.25,3.12-6.59,7.51-4.16,13.37,.16,.4,.34,.64,.57,.79Z"/>
                    </svg>
                `,
                    //shadowUrl: 'leaf-shadow.png',
                    className: "rotated-marker",
                    style: "transform-origin: center;",
                    iconSize: [70, 35], // size of the icon
                    //shadowSize:   [50, 64], // size of the shadow
                    iconAnchor: [35, 19], // point of the icon which will correspond to marker's location
                    //shadowAnchor: [4, 62],  // the same for the shadow
                    popupAnchor: [0, -10] // point from which the popup should open relative to the iconAnchor
                })
        }

        return {
            icon: divIcon,
            popupContent: `<b>${feature.properties.wildlifeType} ${DateTime.fromMillis(feature.properties.time[0], { zone: 'utc'}).toLocal().toLocaleString(DateTime.DATETIME_SHORT)}</b><br>
                           <b>Type:</b> ${feature.properties.wildlifeType}<br>
                           <b>Species:</b> ${feature.properties.species}<br>
                           <b>Direction of Travel:</b> ${feature.properties.directionOfTravel}<br>
                           <b>Action:</b> ${feature.properties.wildlifeAction}<br>
                           <b>Logged By:</b> <a href="https://www.marinetraffic.com/en/ais/details/ships/${feature.properties.loggedByMmsi}" target="_blank">${feature.properties.loggedByMmsi}</a><br>
                           <b>Date:</b> ${DateTime.fromMillis(feature.properties.time[0], { zone: 'utc' }).toLocal().toLocaleString(DateTime.DATETIME_SHORT)}<br>
                           <b>Coordinates:</b>
                           <br>
                           <ul>
                                <li>
                                    <b>Latitude:</b> ${coords.lat}
                                </li>
                                <li>
                                    <b>Longitude:</b> ${coords.lng}
                                </li>
                            </ul>`,
            tooltipContent: `${feature.properties.wildlifeType}`
        };    
    }
}