import * as L from 'leaflet';

import "/src/app/LeafletPlayback.js";
import "/src/app/leaflet.hotline.js";
import "/src/app/Radzen.Blazor.js";
import "/src/app/app.js";
import "/src/app/BlazorQuill.js";
import "/src/app/publicPages.js";

delete L.Icon.Default.prototype._getIconUrl;
L.Icon.Default.mergeOptions({
    iconRetinaUrl: require('leaflet/dist/images/marker-icon-2x.png'),
    iconUrl: require('leaflet/dist/images/marker-icon.png'),
    shadowUrl: require('leaflet/dist/images/marker-shadow.png'),
});

import 'bootstrap/dist/css/bootstrap.min.css';
import 'toastr/toastr.scss';
import 'leaflet/dist/leaflet.css';
import "vis-timeline/styles/vis-timeline-graph2d.css";
