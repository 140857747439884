import * as L from 'leaflet';
import $ from 'jquery';
import { DateTime } from "luxon";
import { CoordinatesHelper } from '../helpers/CoordinatesHelper';

export class FishingGear_DeprecatedProvider {
    constructor(connectionString) {
        this.connectionString = connectionString;
    }

    getAllAsGeoJsonByProjectId(projectId, start) {
        return new Promise((resolve, reject) => {
            $.ajax({
                url: `${this.connectionString}/api/FishingGear_Deprecated/GetAllAsGeoJsonByProjectId`,
                type: 'GET',
                data: {
                    projectId: projectId,
                    start: start
                },
                success: (result) => {
                    resolve(JSON.parse(result));
                },
                error: (error) => {
                    reject(error);
                }
            });
        })
    }

    createTimelineItems(data) {
        let dataBuffer = [];
        data.features.forEach(function (feature) {
            if (dataBuffer.find(x => x.id === feature.properties.featureId)){
                return
            }

            let dateTime = DateTime.fromMillis(feature.properties.time[0]);
            dataBuffer.push({
                id: feature.properties.featureId,
                title: `Fishing Gear`,
                content: `Fishing Gear`,
                start: dateTime.toLocal().toRFC2822(),
                end: dateTime.toLocal().toRFC2822(),
                group: feature.properties.featureType,
                type: 'box'
            });
        });
        return dataBuffer;
    }

    static getMarkerOptions(feature) {

        let coords = CoordinatesHelper.latLngtoDecimalDegreesMinutesString(feature.geometry.coordinates[1], feature.geometry.coordinates[0])

        return {
            icon: L.icon({
                    iconUrl: 'assets/img/map/markers/fishingGearOld.png',
                    iconSize: [25, 32],
            }),
            popupContent: `<b>(Deprecated)Fishing Gear ${feature.properties.featureId}</b><br>
                           <b>Description:</b> ${feature.properties.description}<br> 
                           <b>Type:</b> ${feature.properties.gearType}<br>
                           <b>Vessel Name:</b> ${feature.properties.vesselName}<br>
                           <b>Logged By:</b> <a href="https://www.marinetraffic.com/en/ais/details/ships/${feature.properties.loggedByMmsi}" target="_blank">${feature.properties.loggedByMmsi}</a><br>
                           <b>Date:</b> ${DateTime.fromMillis(feature.properties.time[0], { zone: 'utc' }).toLocal().toLocaleString(DateTime.DATETIME_SHORT)}<br>
                           <b>Coordinates:</b>
                           <br>
                           <ul>
                                <li>
                                    <b>Latitude:</b> ${coords.lat}
                                </li>
                                <li>
                                    <b>Longitude:</b> ${coords.lng}
                                </li>
                            </ul>`,
            tooltipContent: `Fishing Gear`
        };    
    }
}