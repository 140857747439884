import * as L from 'leaflet';
import $ from 'jquery';
import { DateTime } from "luxon";
import { GuardAreaProvider } from './mapTimelineProviders/GuardAreaProvider';
import { SurveyLineProvider } from './mapTimelineProviders/SurveyLineProvider';
import { ProjectPointProvider } from './mapTimelineProviders/ProjectPointProvider';
import { BoundaryAreaProvider } from './mapTimelineProviders/BoundaryAreaProvider';
import { CommunicationProvider } from './mapTimelineProviders/CommunicationProvider';
import { FishingGear_DeprecatedProvider } from './mapTimelineProviders/FishingGear_DeprecatedProvider';
import { FishingGearProvider } from './mapTimelineProviders/FishingGearProvider';
import { FishingGearMarkerProvider } from './mapTimelineProviders/FishingGearMarkerProvider';
import { HealthSafetyEnvironmentEventProvider } from './mapTimelineProviders/HealthSafetyEnvironmentEventProvider';
import { LoggedVesselProvider } from './mapTimelineProviders/LoggedVesselProvider';
import { VesselHistoryProvider } from './mapTimelineProviders/VesselHistoryProvider';
import { WeatherEventProvider } from './mapTimelineProviders/WeatherEventProvider';
import { WildlifeProvider } from './mapTimelineProviders/WildlifeProvider';
import { ProjectCentroidProvider } from './mapTimelineProviders/ProjectCentroidProvider';
import { DataSet, Timeline, timeline as TimelineUtils } from "vis-timeline/standalone";
import "/src/app/LeafletPlayback.js";
import "/src/app/leaflet.hotline.js";
import toastr from 'toastr'
import screenfull from 'screenfull'

export class LeafletMapTimeline {
    constructor(connectionString) {
        this.projectCentroidProvider = new ProjectCentroidProvider(connectionString);
        this.trackDataProvider = new VesselHistoryProvider(connectionString);
        this.fetchId = null;
        this.map = null;
        this.timeline = null;
        this.playback = null;
        this.mapContainer = null;
        this.timelineContainer = null;
        this.currentSliderDate = null;
        this.mostCurrentDateReceived = null;
        this.layerControl = null;
        this.isDataLoading = false;
        this.intervalId = null;
        this.isPaused = false;
        this.isFullscreen = false;

        this.visDataSet = new DataSet();
        this.osm = L.tileLayer('https://tile.openstreetmap.org/{z}/{x}/{y}.png', {
            maxZoom: 19,
            attribution: '&copy; <a href="http://www.openstreetmap.org/copyright">OpenStreetMap</a>'
        });
        this.noaa = L.tileLayer.wms('https://gis.charttools.noaa.gov/arcgis/rest/services/MCS/ENCOnline/MapServer/exts/MaritimeChartService/WMSServer', {
            maxZoom: 19,
            minZoom: 9,
            layers: '2',
            attribution: '&copy; <a href="https://nowcoast.noaa.gov/help/#!section=wms-usage">NOAA</a>'
        });
        this.baseLayers = {
            'OpenStreetMap': this.osm,
            'NOAA RNC': this.noaa
        };
        this.vesselsLayerGroup = L.layerGroup();
        this.projectVesselsLayerGroup = L.layerGroup();
        this.communicationLayerGroup = L.layerGroup();
        this.fishingGearDeprecatedLayerGroup = L.layerGroup();
        this.fishingGearLayerGroup = L.layerGroup();
        this.fishingGearMarkerLayerGroup = L.layerGroup();
        this.healthSafetyEnvironmentLayerGroup = L.layerGroup();
        this.loggedVesselLayerGroup = L.layerGroup();
        this.weatherEventLayerGroup = L.layerGroup();
        this.wildlifeLayerGroup = L.layerGroup();
        this.projectPointLayerGroup = L.layerGroup();
        this.guardAreaLayerGroup = L.layerGroup();
        this.surveyLineLayerGroup = L.layerGroup();
        this.boundaryAreaLayerGroup = L.layerGroup();
        this.overlays = {
            'ProjectVessels': this.projectVesselsLayerGroup,
            'Vessels': this.vesselsLayerGroup,
            'Communication': this.communicationLayerGroup,
            'FishingGearDeprecated': this.fishingGearDeprecatedLayerGroup,
            'Confirmed Fishing Gear Markers': this.fishingGearLayerGroup,
            'Unconfirmed Fishing Gear Markers': this.fishingGearMarkerLayerGroup,
            'HealthSafetyEnvironmentEvent': this.healthSafetyEnvironmentLayerGroup,
            'LoggedVessel': this.loggedVesselLayerGroup,
            'WeatherEvent': this.weatherEventLayerGroup,
            'Wildlife': this.wildlifeLayerGroup,
            'Tracks': this.vesselsLayerGroup,
            'ProjectPoint': this.projectPointLayerGroup,
            'GuardArea': this.guardAreaLayerGroup,
            'BoundaryArea': this.boundaryAreaLayerGroup,
            'SurveyLine': this.surveyLineLayerGroup
        };
        this.geometryProviders = [
            new ProjectPointProvider(connectionString, this.projectPointLayerGroup),
            new GuardAreaProvider(connectionString, this.guardAreaLayerGroup),
            new SurveyLineProvider(connectionString, this.surveyLineLayerGroup),
            new BoundaryAreaProvider(connectionString, this.boundaryAreaLayerGroup)
        ];
        this.playbackDataProviders =
            [
                new CommunicationProvider(connectionString),
                new FishingGear_DeprecatedProvider(connectionString),
                new FishingGearProvider(connectionString),
                new FishingGearMarkerProvider(connectionString),
                new HealthSafetyEnvironmentEventProvider(connectionString),
                new LoggedVesselProvider(connectionString),
                new WeatherEventProvider(connectionString),
                new WildlifeProvider(connectionString)
            ]
    }

    static get MIN_MILLIS_BETWEEN_UPDATES() { return 180000; } //3 minutes

    static get FULLSCREEN_SVG() {
        return `<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-fullscreen" viewBox="0 0 16 16">
            <path d="M1.5 1a.5.5 0 0 0-.5.5v4a.5.5 0 0 1-1 0v-4A1.5 1.5 0 0 1 1.5 0h4a.5.5 0 0 1 0 1h-4zM10 .5a.5.5 0 0 1 .5-.5h4A1.5 1.5 0 0 1 16 1.5v4a.5.5 0 0 1-1 0v-4a.5.5 0 0 0-.5-.5h-4a.5.5 0 0 1-.5-.5zM.5 10a.5.5 0 0 1 .5.5v4a.5.5 0 0 0 .5.5h4a.5.5 0 0 1 0 1h-4A1.5 1.5 0 0 1 0 14.5v-4a.5.5 0 0 1 .5-.5zm15 0a.5.5 0 0 1 .5.5v4a1.5 1.5 0 0 1-1.5 1.5h-4a.5.5 0 0 1 0-1h4a.5.5 0 0 0 .5-.5v-4a.5.5 0 0 1 .5-.5z"/>
        </svg>`
    }

    static get FULLSCREEN_EXIT_SVG() {
        return `<svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" fill="currentColor" class="bi bi-fullscreen-exit" viewBox="0 0 16 16">
            <path d="M5.5 0a.5.5 0 0 1 .5.5v4A1.5 1.5 0 0 1 4.5 6h-4a.5.5 0 0 1 0-1h4a.5.5 0 0 0 .5-.5v-4a.5.5 0 0 1 .5-.5zm5 0a.5.5 0 0 1 .5.5v4a.5.5 0 0 0 .5.5h4a.5.5 0 0 1 0 1h-4A1.5 1.5 0 0 1 10 4.5v-4a.5.5 0 0 1 .5-.5zM0 10.5a.5.5 0 0 1 .5-.5h4A1.5 1.5 0 0 1 6 11.5v4a.5.5 0 0 1-1 0v-4a.5.5 0 0 0-.5-.5h-4a.5.5 0 0 1-.5-.5zm10 1a1.5 1.5 0 0 1 1.5-1.5h4a.5.5 0 0 1 0 1h-4a.5.5 0 0 0-.5.5v4a.5.5 0 0 1-1 0v-4z"/>
        </svg>`
    }

    initWithProjectId(projectId) {

        let mapTimelineContainer = document.getElementById('mapContainer');
        this.mapContainer = document.getElementById('map');
        this.timelineContainer = document.getElementById('timeline');

        if (mapTimelineContainer == null || this.mapContainer == null || this.timelineContainer == null) {
            console.error('Could not find map or timeline container');
            return
        }

        this.fetchId = projectId;
        this.map = this.#initializeMap();
        this.timeline = this.#initTimeline();
        this.playback = this.#initPlayback();
        //handling esc key press that is not handled by button click event
        screenfull.on('change', (e) => {
            if (screenfull.isFullscreen && !this.isFullscreen) {
                this.isFullscreen = true;
                $("#fullscreenBtn").html(LeafletMapTimeline.FULLSCREEN_EXIT_SVG);
            }
            else if (!screenfull.isFullscreen && this.isFullscreen) {
                this.isFullscreen = false;
                $("#fullscreenBtn").html(LeafletMapTimeline.FULLSCREEN_SVG);
            }
        });
        $("#resumeBtn").on('click', () => this.#resumeRealtimeTask());
        $("#fullscreenBtn").on('click', () => this.#toggleFullscreen());

        this.currentSliderDate = DateTime.utc();

        this.projectCentroidProvider.getCentroid(projectId)
            .then((coordinate) => {
                this.centroid = coordinate;
                this.map.setView([coordinate.latitude, coordinate.longitude], 10);

                Promise.all([this.#fetchGeometries(), this.#fetchData(this.currentSliderDate)])
                    .finally(() => {
                        //when no track data is available, the most current date received will be null, so it needs to be assigned to the current date
                        this.mostCurrentDateReceived = this.mostCurrentDateReceived ?? DateTime.utc();
                        this.timeline.addCustomTime(this.mostCurrentDateReceived.toLocal().toRFC2822(), 'playbackSlider')
                        setTimeout(() => {

                            this.timeline.moveTo(this.currentSliderDate.toLocal().toRFC2822());
                            this.playback.setCursor(this.mostCurrentDateReceived.toMillis());
                            this.timeline.setWindow(this.currentSliderDate.toLocal().minus({ hours: 7 }).toRFC2822(), this.currentSliderDate.toLocal().plus({ hours: 1 }).toRFC2822());
                        }, 100);
                        this.#startRealtimeTask();
                    })
            })
            .catch((error) => {
                console.error(error);
                toastr.error('Error fetching project centroid', null, { timeOut: 5000 });
            })
    }

    #getRecentData() {
        this.#fetchData(this.mostCurrentDateReceived).then(() => {
            this.currentSliderDate = this.mostCurrentDateReceived;
            this.timeline.moveTo(this.currentSliderDate.toLocal().toRFC2822());
            this.playback.setCursor(this.currentSliderDate.toMillis());
        })
            .catch((error) => {
                console.trace(error);
                toastr.error('Error fetching new data', null, { timeOut: 5000 });
            });
    }

    #fetchGeometries() {
        return new Promise((resolve, reject) => {
            Promise.all(
                [
                    this.geometryProviders.forEach((provider) => {
                        provider.getAllAsGeoJsonByProjectId(this.fetchId)
                            .then(data => {
                                provider.addToLayer(data);
                            })
                            .catch((error) => {
                                console.trace(error);
                            });
                    })
                ])
                .catch((error) => {
                    console.trace(error);
                })
                .finally(() => {
                    resolve(true);
                })
        })
    }

    #fetchData(timestamp) {
        return new Promise((resolve, reject) => {
            this.isDataLoading = true;
            this.#addMapLoader();

            let start = timestamp.minus({ hours: 6 }).toMillis();
            let end = timestamp.plus({ hours: 6 }).toMillis();

            let geoJsonDataBuffer = [];
            let dataSetBuffer = [];
            let maxDataReceived = null;

            Promise.all([
                this.playbackDataProviders.forEach((provider) => {
                    provider.getAllAsGeoJsonByProjectId(this.fetchId, start, end)
                        .then(data => {
                            geoJsonDataBuffer.push(data);
                            dataSetBuffer.push(provider.createTimelineItems(data));
                        })
                        .catch((error) => {
                            console.trace(error);
                        });
                }),
                this.trackDataProvider.getAllAsGeoJsonByRangeAndPointRadius(start, end, this.centroid.longitude, this.centroid.latitude)
                    .then(data => {
                        geoJsonDataBuffer.push(data);
                        let result = this.trackDataProvider.createTimelineItems(data);
                        dataSetBuffer.push(result.dataBuffer);
                        maxDataReceived = result.maxDataReceived;
                    })
                    .catch((error) => {
                        console.trace(error);
                    })
            ])
                .then(() => {
                    this.playback.clearData();
                    this.visDataSet = new DataSet();
                    this.timeline.setItems(this.visDataSet);

                    geoJsonDataBuffer.forEach((data) => {
                        this.playback.addData(data);
                    })

                    dataSetBuffer.forEach((data) => {
                        this.visDataSet.add(data);
                    })

                    if (this.mostCurrentDateReceived === null || maxDataReceived > this.mostCurrentDateReceived) {
                        this.mostCurrentDateReceived = maxDataReceived;
                    }
                })
                .catch((error) => {
                    console.trace(error);
                })
                .finally(() => {
                    this.#removeMapLoader();
                    this.isDataLoading = false;
                    resolve(true)
                })
        })
    }

    #initializeMap() {
        let layerArray = [this.osm, this.noaa];

        Object.values(this.overlays).forEach((overlay) => {
            layerArray.push(overlay);
        })

        let map = L.map(this.mapContainer, {
            center: [0, 0],
            zoom: 10,
            zoomDelta: 1,
            tap: false,
            wheelPxPerZoomLevel: 100,
            layers: layerArray
        })

        this.layerControl = L.control.layers(this.baseLayers, this.overlays).addTo(map);

        return map;
    }

    #initTimeline() {

        // Configuration for the Timeline
        var options = {
            stack: false,
            align: 'auto',
            showWeekScale: true,
            showCurrentTime: true,
            zoomMax: 2592000000,
            snap: null,
        };

        let timeline = new Timeline(this.timelineContainer, null, options);
        timeline.on('timechange', (properties) => this.#handleTimechangeEvent(properties));
        return timeline;
    }

    #initPlayback() {
        var options = {
            tickLen: 15000,
            speed: 10,
            orientIcons: true,
            fadeMarkersWhenStale: true,
            staleTime: 600000,
            popups: true,
            tooltips: true,
            maxInterpolationTime: 20 * 60 * 1000,
            layer: {
                onEachFeature: (feature, layer) => this.#createHotline(feature, layer),
                getLayerGroup: (feature) => {
                    if (feature.properties.featureType === 'Tracks')
                        return this.tracksLayerGroup;
                }
            },
            marker: {
                options: {
                },
                getPopup: function (featureData) {
                    var result = '';
                    if (featureData && featureData.properties) {
                        if (featureData.properties.featureId)
                            result = result + '<b>MMSI: </b>' + featureData.properties.featureId;
                        if (featureData.properties.name)
                            result = result + '<br><b>Name: </b>' + featureData.properties.name;
                        if (featureData.properties.vesselType) {
                            result = result + '<br><b>Type of Vessel: </b>'
                            if (featureData.properties.featureId.length == 7) {
                                result = result + 'Coast Radio Station';
                            }
                            else if (featureData.properties.featureId.slice(0, 2) == '99') {
                                result = result + 'Navigation Aid';
                            }
                            else {
                                result = result + featureData.properties.vesselType;
                            }
                        }
                    }

                    return result;

                },
                getTooltip: function (featureData) {
                    var result = '';

                    if (featureData && featureData.properties) {
                        if (featureData.properties.featureId)
                            result = result + '<b>MMSI: </b>' + featureData.properties.featureId;
                        if (featureData.properties.name)
                            result = result + '<br><b>Name: </b>' + featureData.properties.name;
                        if (featureData.properties.vesselType) {
                            result = result + '<br><b>Type of Vessel: </b>'
                            if (featureData.properties.featureId.length == 7) {
                                result = result + 'Coast Radio Station';
                            }
                            else if (featureData.properties.featureId.slice(0, 2) == '99') {
                                result = result + 'Navigation Aid';
                            }
                            else {
                                result = result + featureData.properties.vesselType;
                            }
                        }
                    }

                    return result;
                },
                getFeatureId: function (featureData) {
                    return featureData.properties.featureId;
                },
                getTooltipOptions: function (featureData) {
                },
            }
        }

        return new L.Playback(this.map, null, (ms) => this.#OnPlaybackTimeChange(ms), options, this.overlays)
    }

    #handleTimechangeEvent(properties) {
        if (!this.isDataLoading) {
            let newTimestamp = DateTime.fromJSDate(properties.time);

            if (newTimestamp >= this.mostCurrentDateReceived) {
                this.timeline.setCustomTime(this.mostCurrentDateReceived.toLocal().toRFC2822(), 'playbackSlider')
                this.playback.setCursor(this.mostCurrentDateReceived.toMillis());
                return;
            }

            this.#pauseRealtimeTask();
            this.playback.setCursor(newTimestamp.toMillis());

            if (newTimestamp < this.currentSliderDate.minus({ hours: 5 }) || newTimestamp > this.currentSliderDate.plus({ hours: 5 })) {
                this.currentSliderDate = newTimestamp;
                this.#fetchData(newTimestamp).then(() => {
                    this.playback.setCursor(this.currentSliderDate.toMillis());
                })
                    .catch((error) => {
                        console.trace(error);
                        toastr.error('Error fetching new data', null, { timeOut: 5000 })
                    });
            }

        }
        else {
            this.timeline.setCustomTime(this.currentSliderDate.toLocal().toRFC2822(), 'playbackSlider')
        }
    }

    #OnPlaybackTimeChange(ms) {
        this.timeline.setCustomTime(DateTime.fromMillis(ms, { zone: 'utc' }).toLocal().toRFC2822(), 'playbackSlider')
    }

    #addMapLoader() {
        let slider = document.getElementsByClassName('vis-custom-time')[0];

        if (slider)
            slider.style.pointerEvents = 'none';
        $('#resumeBtn').prop('disabled', true)
        this.timelineContainer.style.zIndex = -1;
        this.timelineContainer.style.opacity = 0.5;
        let htmlString = `
        <div style="z-index:1000;position: absolute;top: 50%;height: 0;width:100%" class="d-flex flex-column justify-content-center align-items-center" id="maploading" class="text-center">
            <img style="width:40px;height:40px" src="images/loading.gif" />
        </div>`
        this.timelineContainer.insertAdjacentHTML('beforeend', htmlString);
    }

    #removeMapLoader() {
        let slider = document.getElementsByClassName('vis-custom-time')[0];

        if (slider)
            slider.style.pointerEvents = 'auto';
        let mapLoader = document.getElementById('maploading');
        if (mapLoader != null) {
            mapLoader.remove();
            this.timelineContainer.style.opacity = 1;
            this.timelineContainer.style.zIndex = 1;
        }
        $('#resumeBtn').prop('disabled', false)
    }

    #startRealtimeTask() {
        if (!this.intervalId) {
            this.isPaused = false;
            this.intervalId = setInterval(() => {
                this.#getRecentData();
            }, LeafletMapTimeline.MIN_MILLIS_BETWEEN_UPDATES);
            toastr.info('Map realtime started', null, { timeOut: 5000 })
        }
    }

    #pauseRealtimeTask() {
        if (this.intervalId && !this.isPaused) {
            this.isPaused = true;
            $("#resumeBtn").show()
            clearInterval(this.intervalId);
            this.intervalId = null;
            toastr.info('Map realtime paused', null, { timeOut: 5000 })
        }
    }

    #resumeRealtimeTask() {
        if (!this.intervalId && this.isPaused) {
            this.isPaused = false;
            $("#resumeBtn").hide()
            this.#getRecentData();
            this.intervalId = setInterval(() => {
                this.#getRecentData();
            }, LeafletMapTimeline.MIN_MILLIS_BETWEEN_UPDATES);
            toastr.info("Map realtime resumed", null, { timeOut: 5000 })
        }
    }

    #createHotline(feature, layer) {
        if (feature.properties.featureType === 'Tracks') {
            let latLngs = L.GeoJSON.coordsToLatLngs(feature.geometry.coordinates, 0, false)

            let hotline = L.hotline(latLngs, {
                min: 0,
                max: 10,
                palette: {
                    0.0: '#ff0000',
                    0.4: '#ffff00',
                    1.0: '#008800'
                },
                weight: 5,
                outlineColor: '#000000',
                outlineWidth: .1
            });

            return hotline;
        }
    }

    #toggleFullscreen() {
        let container = document.getElementById('mapContainer');
        let fullscreenBtn = document.getElementById('fullscreenBtn');

        if (container && fullscreenBtn) {
            if (screenfull.isEnabled) {

                if (this.isFullscreen) {
                    screenfull.exit()
                    fullscreenBtn.innerHTML = LeafletMapTimeline.FULLSCREEN_SVG;
                    this.isFullscreen = false;
                }
                else {
                    screenfull.request(container)
                    fullscreenBtn.innerHTML = LeafletMapTimeline.FULLSCREEN_EXIT_SVG;
                    this.isFullscreen = true;
                }
            }
        }
    }

    goToFeatureTimestamp(featureId, timestamp, featureType) {
        if (this.playback && this.timeline && this.isDataLoading == false) {
            this.#pauseRealtimeTask();
            let dt = DateTime.fromMillis(timestamp, { zone: 'utc' });

            if (dt < this.currentSliderDate.minus({ hours: 5 }) || dt > this.currentSliderDate.plus({ hours: 5 })) {
                this.#fetchData(dt).then(() => {
                    this.currentSliderDate = dt.toLocal();
                    this.#handleGoToFeatureTimestampRequest(dt, featureType, featureId);
                })
            }
            else {
                this.#handleGoToFeatureTimestampRequest(dt, featureType, featureId);
            }
        }
    }

    #handleGoToFeatureTimestampRequest(dt, featureType, featureId) {
        this.timeline.moveTo(dt.toLocal().toRFC2822());
        this.playback.setCursor(dt.toMillis());
        this.#tryOpenMarker(featureType, featureId);
    }

    #tryOpenMarker(featureType, featureId) {
        let marker = this.overlays[featureType].getLayers().find((layer) => {
            return layer.options.id === featureId;
        });

        if (marker) {
            this.map.setView(marker.getLatLng());
            let popup = marker.getPopup();

            if (popup) {
                setTimeout(() => {
                    this.map.openPopup(popup, marker.getLatLng());

                }, 125)
            }
        }
    }
}
