import * as L from 'leaflet';
import $ from 'jquery';

export class ProjectPointProvider {
    constructor(connectionString, layerGroup) {
        this.connectionString = connectionString;
        this.layerGroup = layerGroup;
        this.geojsonMarkerOptions = {
            radius: 8,
            fillColor: "#ff7800",
            color: "#000",
            weight: 1,
            opacity: 1,
            fillOpacity: 0.8
        };

    }

    static SubstationIcon(color) { 
        return L.divIcon({
            html: 
            `<svg version="1.1" baseProfile="tiny" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px"
                y="0px" viewBox="0 0 24 40" overflow="visible" xml:space="preserve">
            <g id="Icon">
                
                    <rect x="2" y="6" fill="${color}" stroke="#000000" stroke-width="1.0001" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" width="20" height="8"/>
                
                    <polygon fill="${color}" stroke="#000000" stroke-width="1.0001" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" points="
                    7,14 5,14 2,35 4,35 	"/>
                
                    <polygon fill="${color}" stroke="#000000" stroke-width="1.0001" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" points="
                    17,14 19,14 22,35 20,35 	"/>
            </g>
            <g id="Location">
                <g>
                    <g>
                        <circle fill="#FFFFFF" cx="11.9" cy="35" r="3.5"/>
                        <path d="M11.9,32c1.7,0,3,1.3,3,3s-1.3,3-3,3s-3-1.3-3-3S10.3,32,11.9,32 M11.9,31c-2.2,0-4,1.8-4,4s1.8,4,4,4s4-1.8,4-4
                            S14.2,31,11.9,31L11.9,31z"/>
                    </g>
                    <line fill="none" stroke="#000000" stroke-linecap="round" stroke-miterlimit="10" x1="22" y1="35" x2="15.9" y2="35"/>
                    <line fill="none" stroke="#000000" stroke-linecap="round" stroke-miterlimit="10" x1="8.1" y1="35" x2="2" y2="35"/>
                </g>
            </g>
            </svg>
            `,
            className: "svg-icon",
            iconSize:     [24, 40], // size of the icon
            iconAnchor:   [12, 35], // point of the icon which will correspond to marker's location
            popupAnchor:  [0, -50] // point from which the popup should open relative to the iconAnchor
        });
    }

    static WindmillIcon(color) {
        return L.divIcon({
            html: 
            `<svg version="1.1" baseProfile="tiny" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px"
                y="0px" viewBox="0 0 40 60" overflow="visible" xml:space="preserve">
            <g id="Icon">
                
                    <path fill="${color}" stroke="#000000" stroke-width="1.0001" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                    M36.6,31.6L36.6,31.6l-11.7-8.8c-0.3-0.2-0.8-0.3-1.2-0.3L22,22.9c-0.1-1-0.9-1.7-1.9-1.8l0-16.4c0-0.3-0.3-0.6-0.6-0.6
                    C19.2,4,19,4.2,19,4.5L17,19.1c-0.1,0.4,0.1,0.8,0.3,1.2l1.2,1.4C18.2,22,18,22.5,18,23.1c0,0.2,0,0.5,0.1,0.7L3.8,32.1
                    c-0.3,0.2-0.4,0.6-0.2,0.9c0.2,0.3,0.5,0.4,0.7,0.2l13.5-5.7c0.4-0.2,0.7-0.5,0.8-0.9l0.5-1.5l-0.6,29.2h3l-0.6-29.4
                    c0.2-0.1,0.4-0.2,0.6-0.4l14.4,8.2c0.3,0.2,0.7,0.1,0.9-0.3C36.9,32.2,36.8,31.8,36.6,31.6z"/>
            </g>
            <g id="Location">
                <g>
                    <g>
                        <circle fill="#FFFFFF" cx="20.1" cy="55" r="3.5"/>
                        <path d="M20.1,52c1.7,0,3,1.3,3,3s-1.3,3-3,3s-3-1.3-3-3S18.4,52,20.1,52 M20.1,51c-2.2,0-4,1.8-4,4s1.8,4,4,4s4-1.8,4-4
                            S22.3,51,20.1,51L20.1,51z"/>
                    </g>
                    <line fill="none" stroke="#000000" stroke-linecap="round" stroke-miterlimit="10" x1="30.2" y1="55" x2="24.1" y2="55"/>
                    <line fill="none" stroke="#000000" stroke-linecap="round" stroke-miterlimit="10" x1="16.2" y1="55" x2="10.2" y2="55"/>
                </g>
            </g>
            </svg>
            `,
            className: "svg-icon",
            iconSize:     [40, 60], // size of the icon
            iconAnchor:   [20, 55], // point of the icon which will correspond to marker's location
            popupAnchor:  [0, -50] // point from which the popup should open relative to the iconAnchor
        });
    }

    static FishingBuoyIcon(color) {
        return L.divIcon({
            html: 
            `<svg version="1.1" baseProfile="tiny" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px"
                y="0px" viewBox="0 0 36 45" overflow="visible" xml:space="preserve">
            <g id="Icon">
                <g>
                    <g>
                        <polygon fill="${color}" points="13.3,39.5 14.9,31 23.8,32.6 22.5,39.5 			"/>
                        <path d="M15.3,31.6l7.9,1.5l-1.1,6h-8.1L15.3,31.6 M14.5,30.4L12.7,40h10.2l1.4-7.8L14.5,30.4L14.5,30.4z"/>
                    </g>
                    <g>
                        <path fill="#000000" d="M21.5,32.7c-0.7,0-1.5-0.1-2.3-0.2c-2.9-0.5-4.3-1.6-4.2-2c0-0.1,0.6-0.6,2.3-0.6c0.7,0,1.5,0.1,2.3,0.2
                            c1.3,0.2,2.4,0.6,3.2,1.1c0.8,0.4,1,0.8,1,0.9C23.8,32.3,23.2,32.7,21.5,32.7z"/>
                        <path d="M17.3,30.4L17.3,30.4c0.7,0,1.5,0.1,2.2,0.2c1.9,0.3,3.1,1,3.6,1.3c-0.3,0.1-0.8,0.2-1.6,0.2c-0.7,0-1.5-0.1-2.2-0.2
                            c-1.9-0.3-3.1-1-3.6-1.3C16,30.5,16.5,30.4,17.3,30.4 M17.3,29.4c-1.6,0-2.7,0.3-2.8,1c-0.2,0.9,1.9,2,4.6,2.6
                            c0.9,0.2,1.7,0.2,2.4,0.2c1.6,0,2.7-0.3,2.8-1c0.2-0.9-1.9-2-4.6-2.6C18.9,29.5,18,29.4,17.3,29.4L17.3,29.4z"/>
                    </g>
                </g>
                
                    <line fill="none" stroke="#000000" stroke-width="1.0001" stroke-linecap="square" stroke-miterlimit="10" x1="19.3" y1="31" x2="24.3" y2="4"/>
                <g>
                    <polygon fill="${color}" points="22.5,14.4 24.3,4.6 35,6.6 32.4,10.9 33.2,16.4 		"/>
                    <path d="M24.7,5.2l9.4,1.8l-2.1,3.6l-0.2,0.3l0,0.3l0.6,4.6L23.1,14L24.7,5.2 M23.9,4l-2,10.8L33.7,17l-0.8-6l2.8-4.8L23.9,4
                        L23.9,4z"/>
                </g>
            </g>
            <g id="Location">
                <g>
                    <g>
                        <circle fill="${color}" cx="17.9" cy="40" r="3.5"/>
                        <path d="M17.9,37c1.7,0,3,1.3,3,3s-1.3,3-3,3s-3-1.3-3-3S16.3,37,17.9,37 M17.9,36c-2.2,0-4,1.8-4,4s1.8,4,4,4s4-1.8,4-4
                            S20.2,36,17.9,36L17.9,36z"/>
                    </g>
                    <line fill="none" stroke="#000000" stroke-linecap="round" stroke-miterlimit="10" x1="28" y1="40" x2="21.9" y2="40"/>
                    <line fill="none" stroke="#000000" stroke-linecap="round" stroke-miterlimit="10" x1="14.1" y1="40" x2="8" y2="40"/>
                </g>
            </g>
            </svg>
            `,
            className: "svg-icon",
            iconSize:     [36, 45], // size of the icon
            iconAnchor:   [18, 40], // point of the icon which will correspond to marker's location
            popupAnchor:  [0, -50] // point from which the popup should open relative to the iconAnchor
        });
    }

    static UxoIcon(color) {
        return L.divIcon({
            html: 
            `<svg version="1.1" baseProfile="tiny" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px"
                y="0px" viewBox="0 0 28 30" overflow="visible" xml:space="preserve">
            <g id="Icon">
                <g>
                    
                        <path fill="${color}" stroke="#000000" stroke-width="1.0001" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                        M8.5,5.3L10.9,3l1.1,6.8L8.5,5.3L8.5,5.3z"/>
                    
                        <path fill="${color}" stroke="#000000" stroke-width="1.0001" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                        M24.3,6.1l-5.1,3.5l1.1-2.5l1-2.3L24.3,6.1z"/>
                    
                        <polygon fill="${color}" stroke="#000000" stroke-width="1.0001" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" points="
                        14.5,4.1 17.3,5 15.2,13.5 		"/>
                    
                        <polygon fill="${color}" stroke="#000000" stroke-width="1.0001" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" points="
                        3.4,9.1 5.1,6.6 12.1,13.1 3.4,9.1 		"/>
                    
                        <polygon fill="${color}" stroke="#000000" stroke-width="1.0001" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" points="
                        24.2,19 23.1,22.1 16.2,16.5 24.2,19 		"/>
                    
                        <polygon fill="${color}" stroke="#000000" stroke-width="1.0001" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" points="
                        17.4,14.1 26.1,9.7 26.8,12.9 17.4,14.1 		"/>
                    
                        <path fill="${color}" stroke="#000000" stroke-width="1.0001" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                        M25.7,17.1l-7.9-1.8l6.8,0L25.7,17.1L25.7,17.1z"/>
                    
                        <path fill="${color}" stroke="#000000" stroke-width="1.0001" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                        M10.8,22.2c-2.9-1.5-4.4-4.7-3.6-7.9l6.5,2.1l4.8,4.9C16.4,23.2,13.4,23.5,10.8,22.2L10.8,22.2z"/>
                </g>
            </g>
            <g id="Location">
                <g>
                    <g>
                        <circle fill="#FFFFFF" cx="13.9" cy="25" r="3.5"/>
                        <path d="M13.9,22c1.7,0,3,1.3,3,3s-1.3,3-3,3s-3-1.3-3-3S12.3,22,13.9,22 M13.9,21c-2.2,0-4,1.8-4,4s1.8,4,4,4s4-1.8,4-4
                            S16.2,21,13.9,21L13.9,21z"/>
                    </g>
                    <line fill="none" stroke="#000000" stroke-linecap="round" stroke-miterlimit="10" x1="24" y1="25" x2="17.9" y2="25"/>
                    <line fill="none" stroke="#000000" stroke-linecap="round" stroke-miterlimit="10" x1="10.1" y1="25" x2="4" y2="25"/>
                </g>
            </g>
            </svg>
            `,
            className: "svg-icon",
            iconSize:     [28, 30], // size of the icon
            iconAnchor:   [14, 25], // point of the icon which will correspond to marker's location
            popupAnchor:  [0, -50] // point from which the popup should open relative to the iconAnchor
        });
    }

    static SubSeaObjectIcon (color) {
        return L.divIcon({
            html: 
            `<svg version="1.1" baseProfile="tiny" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px"
                y="0px" viewBox="0 0 24 20" overflow="visible" xml:space="preserve">
            <g id="Icon">
                <g>
                    <polygon fill="${color}" points="3.9,0.5 20.1,0.5 12,13.5 		"/>
                    <path d="M19.2,1L12,12.6L4.8,1H19.2 M21,0H3l9,14.5L21,0L21,0z"/>
                </g>
            </g>
            <g id="Location">
                <g>
                    <g>
                        <circle fill="#FFFFFF" cx="12" cy="15" r="3.5"/>
                        <path d="M12,12c1.7,0,3,1.3,3,3s-1.3,3-3,3s-3-1.3-3-3S10.3,12,12,12 M12,11c-2.2,0-4,1.8-4,4s1.8,4,4,4s4-1.8,4-4S14.2,11,12,11
                            L12,11z"/>
                    </g>
                    <line fill="none" stroke="#000000" stroke-linecap="round" stroke-miterlimit="10" x1="22" y1="15" x2="16" y2="15"/>
                    <line fill="none" stroke="#000000" stroke-linecap="round" stroke-miterlimit="10" x1="8.1" y1="15" x2="2" y2="15"/>
                </g>
            </g>
            </svg>
            `,
            className: "svg-icon",
            iconSize:     [24, 20], // size of the icon
            iconAnchor:   [12, 15], // point of the icon which will correspond to marker's location
            popupAnchor:  [0, -50] // point from which the popup should open relative to the iconAnchor
        })
    }

    getAllAsGeoJsonByProjectId(projectId) {
        return new Promise((resolve, reject) => {
            $.ajax({
                url: `${this.connectionString}/api/ProjectPoint/GetAllAsGeoJsonByProjectId`,
                type: 'GET',
                data: {
                    projectId: projectId
                },
                success: (result) => {
                    resolve(JSON.parse(result));
                },
                error: (error) => {
                    reject(error);
                }
            });
        })
    }

    addToLayer(data) {

        data.features.forEach(feature => {
            L.geoJson(feature, {
                pointToLayer: (feature, latlng) => this.#getMarker(feature, latlng),
            })
            .bindTooltip(
                `<b>Name:</b> ${feature.properties.name} <br>` +
                `<b>Description:</b> ${feature.properties.description}`)
            .addTo(this.layerGroup);
        },)
    }

    #getMarker(feature, latlng) {
        let color = this.#getColorFromColorNumber(feature.properties.color);
        let icon = this.#getIcon(feature.properties.typeOfProjectPoint, color);
        
        return new L.marker(latlng, {
            icon: icon,
        })
    }

    #getIcon(typeOfProjectPoint, color) {
        switch (typeOfProjectPoint) {
            case 1: return ProjectPointProvider.WindmillIcon(color);
            case 2: return ProjectPointProvider.SubstationIcon(color);
            case 3: return ProjectPointProvider.UxoIcon(color);
            case 4: return ProjectPointProvider.SubSeaObjectIcon(color);
            case 5: return ProjectPointProvider.FishingBuoyIcon(color);
            default: return ProjectPointProvider.SubSeaObjectIcon(color);
        }
    }

    #getColorFromColorNumber(colorNumber) {
        switch (colorNumber) {
            case 1: return "red";
            case 2: return "orange";
            case 3: return "yellow";
            case 4: return "green";
            case 5: return "blue";
            case 6: return "aqua";
            case 7: return "purple";
            case 8: return "grey";
            case 9:
            default: return "black";
        }
    }
}