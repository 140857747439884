import * as L from 'leaflet';
import $ from 'jquery';
import { DateTime } from "luxon";
import { CoordinatesHelper } from '../helpers/CoordinatesHelper';

export class LoggedVesselProvider {
    constructor(connectionString) {
        this.connectionString = connectionString;
    }

    getAllAsGeoJsonByProjectId(projectId, start, end) {
        return new Promise((resolve, reject) => {
            $.ajax({
                url: `${this.connectionString}/api/LoggedVessel/GetAllAsGeoJsonByProjectIdAndRange`,
                type: 'GET',
                data: {
                    projectId: projectId,
                    start: start,
                    end: end
                },
                success: (result) => {
                    resolve(JSON.parse(result));
                },
                error: (error) => {
                    reject(error);
                }
            });
        })
    }

    
    createTimelineItems(data) {
        let dataBuffer = [];
        data.features.forEach(function (feature) {
            if (dataBuffer.find(x => x.id === feature.properties.featureId)){
                return
            }

            let dateTime = DateTime.fromMillis(feature.properties.time[0]);
            dataBuffer.push({
                id: feature.properties.featureId,
                title: `Logged Vessel`,
                content: `Logged Vessel`,
                start: dateTime.toLocal().toRFC2822(),
                end: dateTime.toLocal().toRFC2822(),
                group: feature.properties.featureType,
                type: 'box'
            });
        });

        return dataBuffer;
    }

    static getMarkerOptions(feature) {
        let coords = CoordinatesHelper.latLngtoDecimalDegreesMinutesString(feature.geometry.coordinates[1], feature.geometry.coordinates[0])

        return {
            icon: L.divIcon({
                html:
                `<svg version="1.1" x="0px" y="0px" viewBox="0.419 0.208 12.867 28.316" style="transform: rotate(${feature.properties.targetCourseOverGround}deg); enable-background:new 0 0 595.3 841.9;" xmlns="http://www.w3.org/2000/svg">
                    <g transform="matrix(0.09179, 0, 0, 0.081918, -4.862583, -12.040033)" style="">
                    <path style="fill:#FFFFFF;stroke:#000000;stroke-width:15;" d="M183.7,319.9c-10.9-69.5-33.7-124.2-52.6-160.8c-1.5-2.9-5.7-2.9-7.2,0c-18.8,36.8-41.4,91.4-52.3,160.8 C61,387.7,65.3,445.3,71.5,485c0.2,1.5,1.5,2.6,3,2.6c35.4,0.1,70.8,0.1,106.3,0.2c1.5,0,2.8-1.1,3-2.6 C190,445.5,194.4,387.9,183.7,319.9z"/>
                    </g>
                </svg>
                `,
                //shadowUrl: 'leaf-shadow.png',
                className: "rotated-marker",
                iconSize: [15, 30], // size of the icon
                //shadowSize:   [50, 64], // size of the shadow
                iconAnchor: [7, 18], // point of the icon which will correspond to marker's location
                //shadowAnchor: [4, 62],  // the same for the shadow
                popupAnchor: [0, -10] // point from which the popup should open relative to the iconAnchor
            }),
            popupContent: `<b>Logged Vessel ${feature.properties.name} - ${DateTime.fromMillis(feature.properties.time[0], { zone: 'utc'}).toLocal().toLocaleString(DateTime.DATETIME_SHORT)}</b><br>
                           <b>MMSI: </b> <a href="https://www.marinetraffic.com/en/ais/details/ships/${feature.properties.mmsi}" target="_blank">${feature.properties.mmsi}</a><br>
                           <b>Description:</b> ${feature.properties.description}<br>
                           <b>Target Method:</b> ${feature.properties.targetMethod}<br>
                           <b>Vessel Type:</b> ${feature.properties.typeOfVessel}<br>
                           <b>Threat Rating:</b> ${feature.properties.threatRating}<br>
                           <b>Speed over Ground:</b> ${feature.properties.targetSpeedOverGround}<br>
                           <b>Course over Ground:</b> ${feature.properties.targetCourseOverGround}<br>
                           <b>Logged By:</b> <a href="https://www.marinetraffic.com/en/ais/details/ships/${feature.properties.loggedByMmsi}" target="_blank">${feature.properties.loggedByMmsi}</a><br>
                           <b>Date:</b> ${DateTime.fromMillis(feature.properties.time[0], { zone: 'utc' }).toLocal().toLocaleString(DateTime.DATETIME_SHORT)}<br>
                           <b>Coordinates:</b>
                           <br>
                           <ul>
                                <li>
                                    <b>Latitude:</b> ${coords.lat}
                                </li>
                                <li>
                                    <b>Longitude:</b> ${coords.lng}
                                </li>
                            </ul>
                           `,
            tooltipContent: `Logged Vessel`
        };    
    }
}