import { DateTime } from 'luxon';
import { CommunicationProvider } from './mapTimelineProviders/CommunicationProvider';
import { FishingGear_DeprecatedProvider } from './mapTimelineProviders/FishingGear_DeprecatedProvider';
import { FishingGearProvider } from './mapTimelineProviders/FishingGearProvider';
import { FishingGearMarkerProvider } from './mapTimelineProviders/FishingGearMarkerProvider';
import { HealthSafetyEnvironmentEventProvider } from './mapTimelineProviders/HealthSafetyEnvironmentEventProvider';
import { LoggedVesselProvider } from './mapTimelineProviders/LoggedVesselProvider';
import { VesselHistoryProvider } from './mapTimelineProviders/VesselHistoryProvider';
import { CoordinatesHelper } from './helpers/CoordinatesHelper';
import { WeatherEventProvider } from './mapTimelineProviders/WeatherEventProvider';
import { WildlifeProvider } from './mapTimelineProviders/WildlifeProvider';

// UMD initialization to work with CommonJS, AMD and basic browser script include
(function (factory) {
    var L;
    if (typeof define === 'function' && define.amd) {
        // AMD
        define(['leaflet'], factory);
    } else if (typeof module === 'object' && typeof module.exports === "object") {
        // Node/CommonJS
        L = require('leaflet');
        module.exports = factory(L);
    } else {
        // Browser globals
        if (typeof window.L === 'undefined')
            throw 'Leaflet must be loaded first';
        factory(window.L);
    }
}(function (L) {

    L.Playback = L.Playback || {};

    L.Playback.Util = L.Class.extend({
        statics: {

            DateStr: function (time) {
                return 'test';
            },

            TimeStr: function (time) {
                var d = new Date(time);

                var h = d.getHours();
                var m = d.getMinutes();
                var s = d.getSeconds();
                var tms = time / 1000;
                var dec = (tms - Math.floor(tms)).toFixed(2).slice(1);
                var mer = 'AM';
                if (h > 11) {
                    h %= 12;
                    mer = 'PM';
                }
                if (h === 0) h = 12;
                if (m < 10) m = '0' + m;
                if (s < 10) s = '0' + s;
                //      return h + ':' + m + ':' + s + dec + ' ' + mer;
                return 'test';
            },

            ParseGPX: function (gpx) {
                var geojson = {
                    type: 'Feature',
                    geometry: {
                        type: 'MultiPoint',
                        coordinates: []
                    },
                    properties: {
                        time: [],
                        speed: [],
                        altitude: []
                    },
                    bbox: []
                };
                var xml = $.parseXML(gpx);
                var pts = $(xml).find('trkpt');
                for (var i = 0, len = pts.length; i < len; i++) {
                    var p = pts[i];
                    var lat = parseFloat(p.getAttribute('lat'));
                    var lng = parseFloat(p.getAttribute('lon'));
                    var timeStr = $(p).find('time').text();
                    var eleStr = $(p).find('ele').text();
                    var t = new Date(timeStr).getTime();
                    var ele = parseFloat(eleStr);

                    var coords = geojson.geometry.coordinates;
                    var props = geojson.properties;
                    var time = props.time;
                    var altitude = geojson.properties.altitude;

                    coords.push([lng, lat]);
                    time.push(t);
                    altitude.push(ele);
                }
                return geojson;
            }
        }

    });

    L.Playback = L.Playback || {};

    L.Playback.MoveableMarker = L.Marker.extend({
        initialize: function (startLatLng, options, feature) {
            var marker = options.marker || {};
            this.icon = VesselHistoryProvider.getIcon(feature);

            L.Marker.prototype.initialize.call(this, startLatLng, { icon: this.icon });

            this.popupContent = marker.getPopup(feature);
            this.tooltipContent = marker.getTooltip(feature);
            this.tooltipOptions = marker.getTooltipOptions(feature);
            this.featureId = marker.getFeatureId(feature);
            this.feature = feature;

            if (marker.getPopup) {
                this.popupContent = marker.getPopup(feature);
            }

            if (options.popups) {
                this.bindPopup(this.getPopupContent());
            }

            if (options.labels) {
                if (this.bindLabel) {
                    this.bindLabel(this.getPopupContent());
                }
                else {
                    console.log("Label binding requires leaflet-label (https://github.com/Leaflet/Leaflet.label)");
                }
            }

            if (options.tooltips) {
                this.tooltipLabel = this.getTooltipContent();
                this.bindTooltip(this.tooltipLabel, this.getTooltipOptions());
                this.bindTooltip(this.tooltipLabel, this.getTooltipOptions());
            }
        },

        getPopupContent: function () {
            if (this.popupContent !== '') {
                return this.popupContent;
            }

            return '';
        },


        getTooltipContent: function () {
            if (this.tooltipContent !== "") {
                return this.tooltipContent;
            } else {
                return "";
            }
        },


        getTooltipOptions: function () {
            if (this.tooltipOptions !== "") {
                return this.tooltipOptions;
            } else {
                return null;
            }
        },

        getFeatureId: function () {
            if (this.featureId !== "") {
                return this.featureId;
            } else {
                return null;
            }
        },

        move: function (latLng, transitionTime, timestamp, speed, bearing,) {
            // Only if CSS3 transitions are supported
            if (L.DomUtil.TRANSITION) {
                if (this._icon) {
                    this._icon.style[L.DomUtil.TRANSITION] = 'all ' + transitionTime + 'ms linear';
                    if (this._popup && this._popup._wrapper)
                        this._popup._wrapper.style[L.DomUtil.TRANSITION] = 'all ' + transitionTime + 'ms linear';
                    if (this._tooltip && this._tooltip._wrapper)
                        this._tooltip._wrapper.style[L.DomUtil.TRANSITION] = 'all ' + transitionTime + 'ms linear';
                }
                if (this._shadow) {
                    this._shadow.style[L.DomUtil.TRANSITION] = 'all ' + transitionTime + 'ms linear';
                }
            }
            this.setLatLng(latLng);

            if (this._popup) {


                let date = DateTime.fromMillis(timestamp);
                let coords = CoordinatesHelper.latLngtoDecimalDegreesMinutesString(latLng.lat, latLng.lng)
                this._popup.setContent(this.getPopupContent() +
                    '<br><b>Date and Time: </b>' + date.toLocaleString(DateTime.DATETIME_SHORT) +
                    '<br><b>SOG: </b>' + speed +
                    '</br><b>COG: </b>' + bearing +
                    '</br><b>Coordinates:</b><br><ul><li><b>Latitude:</b> '
                    + coords.lat + '</li><li><b>Longitude:</b> ' + coords.lng + '</li></ul>' +
                    '<a href="https://www.marinetraffic.com/en/ais/details/ships/' + this.getFeatureId() + '" target="_blank" /><b>Link to MarineTraffic</b>');

            }

            if (this._tooltip) {
                this._tooltip.setContent(this.getTooltipContent());
            }
        },

        // modify leaflet markers to add our roration code
        /*
         * Based on comments by @runanet and @coomsie 
         * https://github.com/CloudMade/Leaflet/issues/386
         *
         * Wrapping function is needed to preserve L.Marker.update function
         */
        _old__setPos: L.Marker.prototype._setPos,

        _updateImg: function (i, a, s) {
            a = L.point(s).divideBy(2)._subtract(L.point(a));
            var transform = '';
            //        transform += ' translate(' + -a.x + 'px, ' + -a.y + 'px)';
            transform += ' rotate(' + this.options.iconAngle + 'deg)';
            //        transform += ' translate(' + a.x + 'px, ' + a.y + 'px)';
            i.style[L.DomUtil.TRANSFORM] += transform;
        },
        setIconAngle: function (iconAngle) {
            this.options.iconAngle = iconAngle;
            if (this._map)
                this.update();
        },
        _setPos: function (pos) {
            if (this._icon) {
                this._icon.style[L.DomUtil.TRANSFORM] = "";
            }
            if (this._shadow) {
                this._shadow.style[L.DomUtil.TRANSFORM] = "";
            }

            this._old__setPos.apply(this, [pos]);
            if (this.options.iconAngle) {
                var a = this.options.icon.options.iconAnchor;
                var s = this.options.icon.options.iconSize;
                var i;
                if (this._icon) {
                    i = this._icon;
                    this._updateImg(i, a, s);
                }

                if (this._shadow) {
                    // Rotate around the icons anchor.
                    s = this.options.icon.options.shadowSize;
                    i = this._shadow;
                    this._updateImg(i, a, s);
                }

            }
        }
    });

    L.Playback = L.Playback || {};

    L.Playback.Stationary = L.Class.extend({

        initialize: function (geoJSON, options, layergroups) {
            options = options || {};
            let tickLen = options.tickLen || 250;
            this._staleTime = options.staleTime || 60 * 5 * 1000;
            this._fadeMarkersWhenStale = options.fadeMarkersWhenStale || false;

            this._geoJSON = geoJSON;
            this._tickLen = tickLen;
            this._ticks = [];
            this._marker = null;
            this._path = null;
            this._orientations = [];
            this._layergroups = layergroups;
            this._time = geoJSON.properties.time;
            this._coordinates = geoJSON.geometry.coordinates;
            this._orientIcon = options.orientIcons;

        },

        getTime: function () {
            return this._time;
        },

        featureNotPresentAtTick: function (timestamp) {
            return !this.featurePresentAtTick(timestamp);
        },

        featurePresentAtTick: function (timestamp) {
            return (timestamp >= this._time);
        },

        setMarker: function (timestamp, options) {

            if (this._coordinates) {
                let markerOptions = null;

                switch (this._geoJSON.properties.featureType) {
                    case 'FishingGearDeprecated':
                        markerOptions = FishingGear_DeprecatedProvider.getMarkerOptions(this._geoJSON);
                        break;
                    case 'Unconfirmed Fishing Gear Markers':
                        markerOptions = FishingGearMarkerProvider.getMarkerOptions(this._geoJSON);
                        break;
                    case 'LoggedVessel':
                        markerOptions = LoggedVesselProvider.getMarkerOptions(this._geoJSON);
                        break;
                    case 'HealthSafetyEnvironmentEvent':
                        markerOptions = HealthSafetyEnvironmentEventProvider.getMarkerOptions(this._geoJSON);
                        break;
                    case 'Communication':
                        markerOptions = CommunicationProvider.getMarkerOptions(this._geoJSON);
                        break;
                    case 'WeatherEvent':
                        markerOptions = WeatherEventProvider.getMarkerOptions(this._geoJSON);
                        break;
                    case 'Wildlife':
                        markerOptions = WildlifeProvider.getMarkerOptions(this._geoJSON);
                        break;
                    default:
                        console.error("Unknown feature type: " + this._geoJSON.properties.featureType)
                }

                if (markerOptions) {
                    if (Array.isArray(markerOptions)) {

                    }
                    else {
                        this._marker = new L.marker([this._coordinates[1], this._coordinates[0]], {
                            icon: markerOptions.icon,
                            id: this._geoJSON.properties.featureId,
                            featureType: this._geoJSON.properties.featureType
                        })
                            .bindPopup(markerOptions.popupContent)
                            .bindTooltip(markerOptions.tooltipContent);

                        if (options.mouseOverCallback) {
                            this._marker.on('mouseover', options.mouseOverCallback);
                        }
                        if (options.clickCallback) {
                            this._marker.on('click', options.clickCallback);
                        }

                        this.manageMarker(timestamp);
                    }
                }
            }

            return this._marker;
        },


        manageMarker: function (timestamp) {
            if (this.featureNotPresentAtTick(timestamp)) {
                this._marker.removeFrom(this._layergroups[this._geoJSON.properties.featureType]);
            }
            else {
                this._marker.addTo(this._layergroups[this._geoJSON.properties.featureType]);
            }
        },

        getMarker: function () {
            return this._marker;
        }

    });

    L.Playback = L.Playback || {};

    L.Playback.StationaryCollection = L.Class.extend({

        initialize: function (geoJSON, options, layergroups) {
            options = options || {};
            let tickLen = options.tickLen || 250;
            this._staleTime = options.staleTime || 60 * 5 * 1000;
            this._fadeMarkersWhenStale = options.fadeMarkersWhenStale || false;

            this._geoJSON = geoJSON;
            this._tickLen = tickLen;
            this._ticks = [];
            this._currentlyPresentMarkers = [];
            this._markers = [];
            this._path = null;
            this._orientations = [];
            this._layergroups = layergroups;
            this._coordinates = geoJSON.geometry.coordinates;
            this._orientIcon = options.orientIcons;

        },

        getTime: function () {
            return this._time;
        },

        featureNotPresentAtTick: function (featureTime, timestamp) {
            return !this.featurePresentAtTick(featureTime, timestamp);
        },

        featurePresentAtTick: function (featureTime, timestamp) {
            return (timestamp >= featureTime);
        },

        setMarkersAndPath: function (timestamp, options) {

            if (this._coordinates) {

                for (let i = 0; i < this._coordinates.length; i++) {
                    let coordinates = this._coordinates[i];
                    let markerOptions = null;

                    switch (this._geoJSON.properties.featureType) {
                        case 'Confirmed Fishing Gear Markers':
                            markerOptions = FishingGearProvider.getMarkerOptions(this._geoJSON, this._geoJSON.properties.markerFeatures[i]);
                            markerOptions.time = this._geoJSON.properties.markerFeatures[i].properties.time[0];
                            break;
                        default:
                            console.error("Unknown feature type: " + this._geoJSON.properties.featureType)
                    }
                    if (markerOptions) {
                        let marker = new L.marker([coordinates[1], coordinates[0]], {
                            icon: markerOptions.icon,
                            id: this._geoJSON.properties.markerFeatures[i].properties.featureId,
                            featureType: this._geoJSON.properties.featureType,
                            featureTime: markerOptions.time
                        })
                            .bindPopup(markerOptions.popupContent)
                            .bindTooltip(markerOptions.tooltipContent);

                        if (options.mouseOverCallback) {
                            marker.on('mouseover', options.mouseOverCallback);
                        }
                        if (options.clickCallback) {
                            marker.on('click', options.clickCallback);
                        }

                        this._markers.push(marker);
                    }
                }
                
                this.manageMarkersAndPath(timestamp);
                return {
                    markers: this._markers,
                    path: this.path
                }
            }
        },

        manageMarker: function (marker, timestamp) {
            if (this.featureNotPresentAtTick(marker.options.featureTime, timestamp)) {
                marker.removeFrom(this._layergroups[this._geoJSON.properties.featureType]);
                if (this._currentlyPresentMarkers.includes(marker)) {
                    this._currentlyPresentMarkers = this._currentlyPresentMarkers.filter(m => m !== marker);
                }

            }
            else {
                marker.addTo(this._layergroups[this._geoJSON.properties.featureType]);
                if (!this._currentlyPresentMarkers.includes(marker)) {
                    this._currentlyPresentMarkers.push(marker);
                }
            }
        },

        manageMarkersAndPath: function (timestamp) {
            this._markers.forEach(marker => {
                this.manageMarker(marker, timestamp);
            });

            if (this._currentlyPresentMarkers.length < 2) {
                if (this._path) {
                    this._path.removeFrom(this._layergroups[this._geoJSON.properties.featureType]);
                    this._path = null; // Clear the reference after removal
                } 
            } else {
                // Create path with all markers
                let latlngs = [];
                this._currentlyPresentMarkers.forEach(marker => {
                    latlngs.push(marker.getLatLng());
                });
                if (this._path) {
                    this._path.removeFrom(this._layergroups[this._geoJSON.properties.featureType]);
                }
                this._path = new L.polyline(latlngs, { color: '#001aff' });
                this._path.addTo(this._layergroups[this._geoJSON.properties.featureType]);
            }
        },

        getMarkersAndPath: function () {
            return {
                markers: this._markers,
                path: this._path
            };
        }

    });

    L.Playback = L.Playback || {};

    L.Playback.Track = L.Class.extend({

        initialize: function (geoJSON, options, tracksLayer, trackLayer, layergroups) {
            options = options || {};
            let tickLen = options.tickLen || 250;
            this._staleTime = options.staleTime || 60 * 5 * 1000;
            this._fadeMarkersWhenStale = options.fadeMarkersWhenStale || false;

            this._geoJSON = geoJSON;
            this._tickLen = tickLen;
            this._ticks = [];
            this._speeds = [];
            this._bearings = [];
            this._marker = null;
            this._tracksLayer = tracksLayer;
            this._trackLayer = trackLayer; //could be null
            this._orientations = [];
            this._layergroups = layergroups;
            this._orientIcon = options.orientIcons;
            this._isProjectVessel = geoJSON.properties.isProjectVessel;

            let coordinates = geoJSON.geometry.coordinates;
            let times = geoJSON.properties.time;
            let bearings = geoJSON.properties.courseOverGrounds;
            let speeds = geoJSON.properties.speedOverGrounds;

            let previousOrientation;

            let currSample = coordinates[0];
            let firstSpeed = speeds[0];
            let firstBearing = bearings[0];
            let currBearing = bearings[0];
            let currSpeed = bearings[0];
            let nextSample = coordinates[1];

            let currSampleTime = times[0];


            //            
            let t = currSampleTime;  // t is used to iterate through tick times
            let nextSampleTime = times[1];
            let tmod = t % tickLen; // ms past a tick time

            let rem,
                ratio;

            // handle edge case of only one t sample
            if (coordinates.length === 1) {
                if (tmod !== 0)
                    t += tickLen - tmod;
                this._ticks[t] = coordinates[0];
                this._speeds[t] = speeds[0];
                this._bearings[t] = bearings[0];
                this._orientations[t] = this._directionOfPoint(coordinates[0], coordinates[0], firstBearing);
                this._startTime = t;
                this._endTime = t;
                return;
            }

            // interpolate first tick if t not a tick time
            if (tmod !== 0) {
                rem = tickLen - tmod;
                ratio = rem / (nextSampleTime - currSampleTime);
                t += rem;
                this._ticks[t] = this._interpolatePoint(currSample, nextSample, ratio);
                this._speeds[t] = firstSpeed;
                this._bearings[t] = firstBearing;
                this._orientations[t] = this._directionOfPoint(currSample, nextSample, firstBearing);
                previousOrientation = this._orientations[t];
            } else {
                this._ticks[t] = currSample;
                this._speeds[t] = firstSpeed;
                this._bearings[t] = firstBearing;
                this._orientations[t] = this._directionOfPoint(currSample, nextSample, firstBearing);
                previousOrientation = this._orientations[t];
            }

            this._startTime = t;
            t += tickLen;
            while (t < nextSampleTime) {
                ratio = (t - currSampleTime) / (nextSampleTime - currSampleTime);
                this._ticks[t] = this._interpolatePoint(currSample, nextSample, ratio);
                this._speeds[t] = firstSpeed;
                this._bearings[t] = firstBearing;
                this._orientations[t] = this._directionOfPoint(currSample, nextSample, firstBearing);
                previousOrientation = this._orientations[t];
                t += tickLen;
            }

            // iterating through the rest of the samples
            for (var i = 1, len = coordinates.length; i < len; i++) {
                currSample = coordinates[i];
                nextSample = coordinates[i + 1];
                t = currSampleTime = times[i];
                nextSampleTime = times[i + 1];


                currBearing = bearings[i];
                currSpeed = speeds[i];

                tmod = t % tickLen;
                if (tmod !== 0 && nextSampleTime) {
                    rem = tickLen - tmod;
                    ratio = rem / (nextSampleTime - currSampleTime);
                    t += rem;
                    this._ticks[t] = this._interpolatePoint(currSample, nextSample, ratio);
                    this._speeds[t] = currSpeed;
                    this._bearings[t] = currBearing;
                    if (nextSample) {
                        this._orientations[t] = this._directionOfPoint(currSample, nextSample, currBearing);
                        previousOrientation = this._orientations[t];
                    } else {
                        this._orientations[t] = previousOrientation;
                    }
                } else {
                    this._ticks[t] = currSample;
                    this._speeds[t] = currSpeed;
                    this._bearings[t] = currBearing;
                    if (nextSample) {
                        this._orientations[t] = this._directionOfPoint(currSample, nextSample, null);
                        previousOrientation = this._orientations[t];
                    } else {
                        this._orientations[t] = previousOrientation;
                    }
                }

                t += tickLen;
                while (t < nextSampleTime) {
                    ratio = (t - currSampleTime) / (nextSampleTime - currSampleTime);

                    if (nextSampleTime - currSampleTime > options.maxInterpolationTime) {
                        this._ticks[t] = currSample;
                        this._speeds[t] = currSpeed;
                        this._bearings[t] = currBearing;
                        if (nextSample) {
                            this._orientations[t] = this._directionOfPoint(currSample, nextSample, null);
                            previousOrientation = this._orientations[t];
                        } else {
                            this._orientations[t] = previousOrientation;
                        }
                    }
                    else {
                        this._ticks[t] = this._interpolatePoint(currSample, nextSample, ratio);
                        this._speeds[t] = currSpeed;
                        this._bearings[t] = currBearing;
                        if (nextSample) {
                            this._orientations[t] = this._directionOfPoint(currSample, nextSample, currBearing);
                            previousOrientation = this._orientations[t];
                        } else {
                            this._orientations[t] = previousOrientation;
                        }
                    }

                    t += tickLen;
                }
            }

            // the last t in the while would be past bounds
            this._endTime = t - tickLen;
            this._lastTick = this._ticks[this._endTime];

        },

        _interpolatePoint: function (start, end, ratio) {
            try {
                var delta = [end[0] - start[0], end[1] - start[1]];
                var offset = [delta[0] * ratio, delta[1] * ratio];
                return [start[0] + offset[0], start[1] + offset[1]];
            } catch (e) {
                console.log('err: cant interpolate a point');
                console.log(['start', start]);
                console.log(['end', end]);
                console.log(['ratio', ratio]);
            }
        },

        _directionOfPoint: function (start, end, bearing) {
            return this._getBearing(start[1], start[0], end[1], end[0], bearing);
        },

        _getBearing: function (startLat, startLong, endLat, endLong, bearing) {

            if (bearing != null) {
                return bearing;

            }
            else {
                startLat = this._radians(startLat);
                startLong = this._radians(startLong);
                endLat = this._radians(endLat);
                endLong = this._radians(endLong);

                var dLong = endLong - startLong;

                var dPhi = Math.log(Math.tan(endLat / 2.0 + Math.PI / 4.0) / Math.tan(startLat / 2.0 + Math.PI / 4.0));
                if (Math.abs(dLong) > Math.PI) {
                    if (dLong > 0.0)
                        dLong = -(2.0 * Math.PI - dLong);
                    else
                        dLong = (2.0 * Math.PI + dLong);
                }

                return (this._degrees(Math.atan2(dLong, dPhi)) + 360.0) % 360.0;
            }


        },

        _radians: function (n) {
            return n * (Math.PI / 180);
        },
        _degrees: function (n) {
            return n * (180 / Math.PI);
        },

        getFirstBearing: function () {
            return this._bearings[this._startTime];
        },

        getFirstTick: function () {
            return this._ticks[this._startTime];
        },


        getLastTick: function () {
            return this._ticks[this._endTime];
        },

        getStartTime: function () {
            return this._startTime;
        },

        getEndTime: function () {
            return this._endTime;
        },

        getTickMultiPoint: function () {
            var t = this.getStartTime();
            var endT = this.getEndTime();
            var coordinates = [];
            var time = [];
            while (t <= endT) {
                time.push(t);
                coordinates.push(this.tick(t));
                t += this._tickLen;
            }

            return {
                type: 'Feature',
                geometry: {
                    type: 'MultiPoint',
                    coordinates: coordinates
                },
                properties: {
                    time: time
                }
            };
        },

        trackPresentAtTick: function (timestamp) {
            var graceperiod = 360000
            return (timestamp >= this._startTime && timestamp < this._endTime + graceperiod)
        },

        featureBeforeTick: function (timestamp) {
            return (timestamp < this._endTime);
        },

        trackStaleAtTick: function (timestamp) {
            return ((this._endTime + this._staleTime) <= timestamp);
        },

        tick: function (timestamp) {
            if (timestamp > this._endTime)
                timestamp = this._endTime;
            if (timestamp < this._startTime)
                timestamp = this._startTime;
            return this._ticks[timestamp];
        },

        getSpeed: function (timestamp) {
            if (timestamp > this._endTime)
                timestamp = this._endTime;
            if (timestamp < this._startTime)
                timestamp = this._startTime;
            return this._speeds[timestamp];
        },

        getBearing: function (timestamp) {
            if (timestamp > this._endTime)
                timestamp = this._endTime;
            if (timestamp < this._startTime)
                timestamp = this._startTime;
            return this._bearings[timestamp];
        },

        getProjectTimezone: function () {
            return this._projectTimezone;
        },

        courseAtTime: function (timestamp) {
            //return 90;
            if (timestamp > this._endTime)
                timestamp = this._endTime;
            if (timestamp < this._startTime)
                timestamp = this._startTime;
            return this._orientations[timestamp];
        },

        setMarker: function (timestamp, options) {
            var lngLat = null;
            // if time stamp is not set, then get first tick
            if (timestamp) {
                lngLat = this.tick(timestamp);
            }
            else {
                lngLat = this.getFirstTick();
            }

            if (lngLat) {
                let latLng = new L.LatLng(lngLat[1], lngLat[0]);
                this._marker = new L.Playback.MoveableMarker(latLng, options, this._geoJSON);
                if (options.mouseOverCallback) {
                    this._marker.on('mouseover', options.mouseOverCallback);
                }
                if (options.clickCallback) {
                    this._marker.on('click', options.clickCallback);
                }

                //hide the marker if its not present yet and fadeMarkersWhenStale is true

                if (this._fadeMarkersWhenStale && !this.trackPresentAtTick(timestamp)) {
                    this._marker.removeFrom(this._layergroups[this._geoJSON.properties.layer_group]);
                }
            }


            return this._marker;
        },

        moveMarker: function (latLng, transitionTime, timestamp, speed, bearing, projectTimezone) {
            if (this._marker) {
                let vesselLayer = this._isProjectVessel ? 'ProjectVessels' : 'Vessels';

                if (this._fadeMarkersWhenStale) {
                    //show the marker if its now present
                    if (this.trackPresentAtTick(timestamp)) {
                        if (!this._layergroups[vesselLayer].hasLayer(this._geoJSON)) {

                            this._marker.addTo(this._layergroups[vesselLayer]);
                            if (this._trackLayer) {
                                this._tracksLayer.addLayer(this._trackLayer);
                            }
                        }

                    } else {
                        if (this.trackStaleAtTick(timestamp)) {
                            this._marker.removeFrom(this._layergroups[vesselLayer]);
                            if (this._trackLayer) {
                                this._tracksLayer.removeLayer(this._trackLayer);
                            }
                        }
                    }

                }

                if (this._orientIcon) {
                    this._marker.setIconAngle(this.courseAtTime(timestamp));
                }

                this._marker.move(latLng, transitionTime, timestamp, speed, bearing, projectTimezone);
            }
        },

        getMarker: function () {
            return this._marker;
        }

    });

    L.Playback = L.Playback || {};

    L.Playback.TrackController = L.Class.extend({

        initialize: function (map, tracks, options, layergroups) {
            this.options = options || {};
            this._map = map;
            this._layergroups = layergroups;

            this._tracks = [];

            // initialize tick points
            this.setTracks(tracks);
        },

        clearTracks: function () {
            while (this._tracks.length > 0) {
                var track = this._tracks.pop();
                var marker = track.getMarker();

                if (marker) {
                    this._map.removeLayer(marker);
                }
            }
        },

        setTracks: function (tracks) {
            // reset current tracks
            this.clearTracks();

            this.addTracks(tracks);
        },

        addTracks: function (tracks) {
            // return if nothing is set
            if (!tracks) {
                return;
            }

            if (tracks instanceof Array) {
                for (var i = 0, len = tracks.length; i < len; i++) {
                    this.addTrack(tracks[i]);
                }
            } else {
                this.addTrack(tracks);
            }
        },

        // add single track
        addTrack: function (track, timestamp) {
            // return if nothing is set
            if (!track) {
                return;
            }

            let marker = track.setMarker(timestamp, this.options);

            if (marker) {

                let vesselLayer = track._isProjectVessel ? 'ProjectVessels' : 'Vessels';

                if (!this._layergroups[vesselLayer].hasLayer(track._geoJSON)) {
                    marker.addTo(this._layergroups[vesselLayer]);
                }

                this._tracks.push(track);
            }
        },

        tock: function (timestamp, transitionTime) {

            for (var i = 0, len = this._tracks.length; i < len; i++) {
                if (this._tracks[i] instanceof L.Playback.Stationary) {
                    this._tracks[i].manageMarker(timestamp);
                }
                else {
                    var lngLat = this._tracks[i].tick(timestamp);
                    var latLng = new L.LatLng(lngLat[1], lngLat[0]);
                    var speed = this._tracks[i].getSpeed(timestamp);
                    var bearing = this._tracks[i].getBearing(timestamp);
                    this._tracks[i].moveMarker(latLng, transitionTime, timestamp, speed, bearing);
                }
            }
        },

        getStartTime: function () {
            var earliestTime = 0;

            if (this._tracks.length > 0) {
                earliestTime = this._tracks[0].getStartTime();
                for (var i = 1, len = this._tracks.length; i < len; i++) {
                    var t = this._tracks[i].getStartTime();
                    if (t < earliestTime) {
                        earliestTime = t;
                    }
                }
            }

            return earliestTime;
        },

        getEndTime: function () {
            var latestTime = 0;

            if (this._tracks.length > 0) {
                latestTime = this._tracks[0].getEndTime();
                for (var i = 1, len = this._tracks.length; i < len; i++) {
                    var t = this._tracks[i].getEndTime();
                    if (t > latestTime) {
                        latestTime = t;
                    }
                }
            }

            return latestTime;
        },

        getTracks: function () {
            return this._tracks;
        }
    });

    L.Playback = L.Playback || {};

    L.Playback.StationaryController = L.Class.extend({

        initialize: function (map, stationaryList, options, layergroups) {
            this.options = options || {};
            this._map = map;
            this._layergroups = layergroups;

            this._stationaries = [];

            // initialize tick points
            this.setStationaries(stationaryList);
        },

        clearStationaries: function () {
            while (this._stationaries.length > 0) {
                var stationary = this._stationaries.pop();
                var marker = stationary.getMarker();

                if (marker) {
                    this._map.removeLayer(marker);
                }
            }
        },

        setStationaries: function (stationaries) {
            // reset current tracks
            this.clearStationaries();

            this.addStationaries(stationaries);
        },

        addStationaries: function (stationaryList) {
            // return if nothing is set
            if (!stationaryList) {
                return;
            }

            if (stationaryList instanceof Array) {
                for (var i = 0, len = stationaryList.length; i < len; i++) {
                    this.addStationary(stationaryList[i]);
                }
            } else {
                this.addStationary(stationaryList);
            }
        },

        // add single track
        addStationary: function (stationary, timestamp) {
            // return if nothing is set
            if (!stationary) {
                return;
            }

            let marker = stationary.setMarker(timestamp, this.options);

            if (marker) {

                if (!this._layergroups[stationary._geoJSON.properties.featureType].hasLayer(marker)) {
                    marker.addTo(this._layergroups[stationary._geoJSON.properties.featureType]);
                }

                this._stationaries.push(stationary);
            }
        },

        tock: function (timestamp, transitionTime) {
            for (var i = 0, len = this._stationaries.length; i < len; i++) {
                this._stationaries[i].manageMarker(timestamp);
            }
        },

        getStationaries: function () {
            return this._stationaries;
        }
    });

    L.Playback = L.Playback || {};

    L.Playback.StationaryCollectionsController = L.Class.extend({

        initialize: function (map, stationaryCollections, options, layergroups) {
            this.options = options || {};
            this._map = map;
            this._layergroups = layergroups;

            this._stationaryCollections = [];

            // initialize tick points
            this.setStationaryCollections(stationaryCollections);
        },

        clearStationaryCollections: function () {
            while (this._stationaryCollections.length > 0) {
                var stationaryCollection = this._stationaryCollections.pop();
                var markersAndPath = stationaryCollection.getMarkersAndPath();

                markersAndPath.markers.forEach(marker => {
                    this._map.removeLayer(marker);
                })

                if (markersAndPath.path) {
                    this._map.removeLayer(markersAndPath.path);
                }
            }
        },

        setStationaryCollections: function (stationaryCollections) {
            this.clearStationaryCollections();

            this.addStationaryCollections(stationaryCollections);
        },

        addStationaryCollections: function (stationaryCollections) {
            // return if nothing is set
            if (!stationaryCollections) {
                return;
            }

            if (stationaryCollections instanceof Array) {
                for (var i = 0, len = stationaryCollections.length; i < len; i++) {
                    this.addStationaryCollection(stationaryCollections[i]);
                }
            } else {
                this.addStationaryCollection(stationaryCollections);
            }
        },

        // add single track
        addStationaryCollection: function (stationaryCollection, timestamp) {
            // return if nothing is set
            if (!stationaryCollection) {
                return;
            }

            let markersAndPath = stationaryCollection.setMarkersAndPath(timestamp, this.options);

            markersAndPath.markers.forEach(marker => {
                if (!this._layergroups[stationaryCollection._geoJSON.properties.featureType].hasLayer(marker)) {
                    marker.addTo(this._layergroups[stationaryCollection._geoJSON.properties.featureType]);
                }
            })

            let path = markersAndPath.path;
            if (path && !this._layergroups[stationaryCollection._geoJSON.properties.featureType].hasLayer(path)) {
                path.addTo(this._layergroups[stationaryCollection._geoJSON.properties.featureType]);
            }

            this._stationaryCollections.push(stationaryCollection);
        },

        tock: function (timestamp, transitionTime) {
            for (var i = 0, len = this._stationaryCollections.length; i < len; i++) {
                this._stationaryCollections[i].manageMarkersAndPath(timestamp);
            }
        },

        getStationaryCollections: function () {
            return this._stationaryCollections;
        }
    });

    L.Playback = L.Playback || {};

    L.Playback.Clock = L.Class.extend({

        initialize: function (trackController, stationaryController, stationaryCollectionsController, callback, options) {
            this._trackController = trackController;
            this._stationaryController = stationaryController;
            this._stationaryCollectionsController = stationaryCollectionsController;
            this._callbacksArry = [];
            if (callback) this.addCallback(callback);
            L.setOptions(this, options);
            this._speed = this.options.speed;
            this._tickLen = this.options.tickLen;
            this._cursor = trackController.getStartTime();
            this._transitionTime = this._tickLen / this._speed;
        },

        _tick: function (self) {
            if (self._cursor > self._trackController.getEndTime()) {
                clearInterval(self._intervalID);
                return;
            }
            self._trackController.tock(self._cursor, self._transitionTime);
            self._stationaryController.tock(self._cursor, self._transitionTime);
            self._stationaryCollectionsController.tock(self._cursor, self._transitionTime);
            self._callbacks(self._cursor);
            self._cursor += self._tickLen;
        },

        _callbacks: function (cursor) {
            var arry = this._callbacksArry;
            for (var i = 0, len = arry.length; i < len; i++) {
                arry[i](cursor);
            }
        },

        addCallback: function (fn) {
            this._callbacksArry.push(fn);
        },

        start: function () {
            if (this._intervalID) return;
            this._intervalID = window.setInterval(
                this._tick,
                this._transitionTime,
                this);
        },

        stop: function () {
            if (!this._intervalID) return;
            clearInterval(this._intervalID);
            this._intervalID = null;
        },

        getSpeed: function () {
            return this._speed;
        },

        isPlaying: function () {
            return this._intervalID ? true : false;
        },

        setSpeed: function (speed) {
            this._speed = speed;
            this._transitionTime = this._tickLen / speed;
            if (this._intervalID) {
                this.stop();
                this.start();
            }
        },

        setCursor: function (ms) {

            var time = parseInt(ms);
            if (!time) return;
            var mod = time % this._tickLen;
            if (mod !== 0) {
                time += this._tickLen - mod;
            }

            this._cursor = time;
            this._trackController.tock(this._cursor, 0);
            this._stationaryController.tock(this._cursor, 0);
            this._stationaryCollectionsController.tock(this._cursor, 0);
            this._callbacks(this._cursor);
        },

        getTime: function () {
            return this._cursor;
        },

        getStartTime: function () {
            return this._trackController.getStartTime();
        },

        getEndTime: function () {
            return this._trackController.getEndTime();
        },

        getTickLen: function () {
            return this._tickLen;
        }

    });

    // Simply shows all of the track points as circles.
    // TODO: Associate circle color with the marker color.

    L.Playback = L.Playback || {};

    L.Playback.TracksLayer = L.Class.extend({
        options: {
            hotline: {
                min: 0,
                max: 10,
                palette: {
                    0.0: '#ff0000',
                    0.4: '#ffff00',
                    1.0: '#008800'
                },
                weight: 3,
                outlineColor: '#000000',
                outlineWidth: .1
            }
        },
        initialize: function (map, options, layergroups) {
            this.trackLayer = layergroups['Tracks'];
            /* 
                        L.control.layers(null, layergroups, {
                            collapsed: false,
                        }).addTo(map); */
        },

        // clear all geoJSON layers
        clearFeatures: function () {
            this.trackLayer.clearLayers();
        },

        // add new geoJSON layer
        addFeature: function (geoJsonFeature) {
            let latLngs = L.GeoJSON.coordsToLatLngs(geoJsonFeature.geometry.coordinates, 0, false)
            return L.hotline(latLngs, this.options.hotline).addTo(this.trackLayer);
        },

        getLayer: function () {
            return this.trackLayer;
        }
    });

    L.Playback = L.Playback || {};

    L.Playback.DateControl = L.Control.extend({
        options: {
            position: 'bottomleft',
            dateFormatFn: L.Playback.Util.DateStr,
            timeFormatFn: L.Playback.Util.TimeStr
        },

        initialize: function (playback, options) {
            L.setOptions(this, options);
            this.playback = playback;
        },

        onAdd: function (map) {
            this._container = L.DomUtil.create('div', 'leaflet-control-layers leaflet-control-layers-expanded');

            var self = this;
            var playback = this.playback;
            var time = playback.getTime();

            var datetime = L.DomUtil.create('div', 'datetimeControl', this._container);

            // date time
            this._date = L.DomUtil.create('p', '', datetime);
            this._time = L.DomUtil.create('p', '', datetime);

            this._date.innerHTML = this.options.dateFormatFn(time);
            this._time.innerHTML = this.options.timeFormatFn(time);

            // setup callback
            playback.addCallback(function (ms) {
                self._date.innerHTML = self.options.dateFormatFn(ms);
                self._time.innerHTML = self.options.timeFormatFn(ms);
            });

            return this._container;
        }
    });

    L.Playback.PlayControl = L.Control.extend({
        options: {
            position: 'bottomright'
        },

        initialize: function (playback) {
            this.playback = playback;
        },

        onAdd: function (map) {
            this._container = L.DomUtil.create('div', 'leaflet-control-layers leaflet-control-layers-expanded');

            var self = this;
            var playback = this.playback;
            playback.setSpeed(100);

            var playControl = L.DomUtil.create('div', 'playControl', this._container);


            this._button = L.DomUtil.create('button', '', playControl);
            this._button.innerHTML = 'Play';


            var stop = L.DomEvent.stopPropagation;

            L.DomEvent
                .on(this._button, 'click', stop)
                .on(this._button, 'mousedown', stop)
                .on(this._button, 'dblclick', stop)
                .on(this._button, 'click', L.DomEvent.preventDefault)
                .on(this._button, 'click', play, this);

            function play() {
                if (playback.isPlaying()) {
                    playback.stop();
                    self._button.innerHTML = 'Play';
                }
                else {
                    playback.start();
                    self._button.innerHTML = 'Stop';
                }
            }

            return this._container;
        }
    });

    L.Playback.SliderControl = L.Control.extend({
        options: {
            position: 'bottomleft'
        },

        initialize: function (playback) {
            this.playback = playback;
        },

        onAdd: function (map) {
            this._container = L.DomUtil.create('div', 'leaflet-control-layers leaflet-control-layers-expanded');

            var self = this;
            var playback = this.playback;

            // slider
            this._slider = L.DomUtil.create('input', 'slider', this._container);
            this._slider.type = 'range';
            this._slider.min = playback.getStartTime();
            this._slider.max = playback.getEndTime();
            this._slider.value = playback.getTime();

            var stop = L.DomEvent.stopPropagation;

            L.DomEvent
                .on(this._slider, 'click', stop)
                .on(this._slider, 'mousedown', stop)
                .on(this._slider, 'dblclick', stop)
                .on(this._slider, 'click', L.DomEvent.preventDefault)
                //.on(this._slider, 'mousemove', L.DomEvent.preventDefault)
                .on(this._slider, 'change', onSliderChange, this)
                .on(this._slider, 'mousemove', onSliderChange, this);


            function onSliderChange(e) {
                var val = Number(e.target.value);
                playback.setCursor(val);
            }

            playback.addCallback(function (ms) {
                self._slider.value = ms;
            });


            map.on('playback:add_tracks', function () {
                self._slider.min = playback.getStartTime();
                self._slider.max = playback.getEndTime();
                self._slider.value = playback.getTime();
            });

            return this._container;
        }
    });

    L.Playback = L.Playback.Clock.extend({
        statics: {
            MoveableMarker: L.Playback.MoveableMarker,
            Track: L.Playback.Track,
            Stationary: L.Playback.Stationary,
            StationaryCollection: L.Playback.StationaryCollection,
            TrackController: L.Playback.TrackController,
            StationaryController: L.Playback.StationaryController,
            StationaryCollectionsController: L.Playback.StationaryCollectionsController,
            Clock: L.Playback.Clock,
            Util: L.Playback.Util,

            TracksLayer: L.Playback.TracksLayer,
            PlayControl: L.Playback.PlayControl,
            DateControl: L.Playback.DateControl,
            SliderControl: L.Playback.SliderControl
        },

        options: {
            tickLen: 250,
            speed: 1,
            maxInterpolationTime: 5 * 60 * 1000, // 5 minutes
            tracksLayer: true,
            playControl: false,
            dateControl: false,
            sliderControl: false,
            // options
            layer: {
                // pointToLayer(featureData, latlng)
            },
            marker: {
                // getPopup(feature)
            }
        },

        initialize: function (map, geoJSON, callback, options, layergroups) {
            L.setOptions(this, options);

            this._map = map;
            this._layergroups = layergroups;
            this._trackLayer = null;

            this._trackController = new L.Playback.TrackController(map, null, this.options, layergroups);
            this._stationaryController = new L.Playback.StationaryController(map, null, this.options, layergroups);
            this._stationaryCollectionsController = new L.Playback.StationaryCollectionsController(map, null, this.options, layergroups);
            L.Playback.Clock.prototype.initialize.call(this, this._trackController, this._stationaryController, this._stationaryCollectionsController, callback, this.options);


            if (this.options.tracksLayer) {
                this._tracksLayer = new L.Playback.TracksLayer(map, options, layergroups);
            }
            else {
                L.control.layers(null, layergroups, {
                    collapsed: false
                }).addTo(map);
            }

            this.setData(geoJSON);

            if (this.options.playControl) {
                this.playControl = new L.Playback.PlayControl(this);
                this.playControl.addTo(map);
            }

            if (this.options.sliderControl) {
                this.sliderControl = new L.Playback.SliderControl(this);
                this.sliderControl.addTo(map);
            }

            if (this.options.dateControl) {
                this.dateControl = new L.Playback.DateControl(this, options);
                this.dateControl.addTo(map);
            }

        },

        clearData: function () {
            this._trackController.clearTracks();
            this._stationaryController.clearStationaries();
            this._stationaryCollectionsController.clearStationaryCollections();

            if (this._tracksLayer) {
                this._tracksLayer.clearFeatures();
            }
        },

        setData: function (geoJSON) {
            this.clearData();
            this.addData(geoJSON, this.getTime());
        },

        // bad implementation
        addData: function (geoJSON, ms) {
            // return if data not set
            if (!geoJSON) {
                return;
            }

            if (geoJSON.type === 'FeatureCollection') {
                for (let i = 0, len = geoJSON.features.length; i < len; i++) {
                    if (geoJSON.features[i].properties.featureType === 'Tracks') {
                        let trackLayer = null;

                        if (this.options.tracksLayer) {
                            trackLayer = this._tracksLayer.addFeature(geoJSON.features[i]);
                        }

                        this._trackController.addTrack(new L.Playback.Track(geoJSON.features[i], this.options, this._tracksLayer.getLayer(), trackLayer, this._layergroups), ms);
                    }
                    else {
                        if (geoJSON.features[i].properties.isStationaryCollection) {
                            this._stationaryCollectionsController.addStationaryCollection(new L.Playback.StationaryCollection(geoJSON.features[i], this.options, this._layergroups), ms);
                        }
                        else {
                            this._stationaryController.addStationary(new L.Playback.Stationary(geoJSON.features[i], this.options, this._layergroups), ms);
                        }
                    }

                }
            } else {
                if (geoJSON.properties.featureType === 'Tracks') {
                    let trackLayer = null;

                    if (this.options.tracksLayer) {
                        trackLayer = this._tracksLayer.addFeature(geoJSON);
                    }

                    this._trackController.addTrack(new L.Playback.Track(geoJSON, this.options, this._tracksLayer.getLayer(), trackLayer, this._layergroups), ms);
                }
                else {
                    if (geoJSON.properties.isStationaryCollection) {
                        this._stationaryCollectionsController.addStationaryCollection(new L.Playback.StationaryCollection(geoJSON, this.options, this._layergroups), ms);
                    }
                    else {
                        this._stationaryController.addStationary(new L.Playback.Stationary(geoJSON, this.options, this._layergroups), ms);
                    }
                }
            }

            this._map.fire('playback:set:data');
        },

        destroy: function () {
            this.clearData();
            if (this.playControl) {
                this._map.removeControl(this.playControl);
            }
            if (this.sliderControl) {
                this._map.removeControl(this.sliderControl);
            }
            if (this.dateControl) {
                this._map.removeControl(this.dateControl);
            }
        }
    });

    L.Map.addInitHook(function () {
        if (this.options.playback) {
            this.playback = new L.Playback(this);
        }
    });

    L.playback = function (map, geoJSON, callback, options, layergroups) {
        return new L.Playback(map, geoJSON, callback, options, layergroups);
    };
    return L.Playback;

}));