import * as L from 'leaflet';
import $ from 'jquery';
import { DateTime } from "luxon";
import { CoordinatesHelper } from '../helpers/CoordinatesHelper';

export class CommunicationProvider {
    constructor(connectionString) {
        this.connectionString = connectionString;
    }

    getAllAsGeoJsonByProjectId(projectId, start, end) {
        return new Promise((resolve, reject) => {
            $.ajax({
                url: `${this.connectionString}/api/Communication/GetAllAsGeoJsonByProjectIdAndRange`,
                type: 'GET',
                data: {
                    projectId: projectId,
                    start: start,
                    end: end
                },
                success: (result) => {
                    resolve(JSON.parse(result));
                },
                error: (error) => {
                    reject(error);
                }
            });
        })
    }

    
    createTimelineItems(data) {
        let dataBuffer = [];
        data.features.forEach(function (feature) {
            if (dataBuffer.find(x => x.id === feature.properties.featureId)){
                return
            }

            let dateTime = DateTime.fromMillis(feature.properties.time[0]);
            dataBuffer.push({
                id: feature.properties.featureId,
                title: feature.properties.callType,
                content: feature.properties.callType,
                start: dateTime.toLocal().toRFC2822(),
                end: dateTime.toLocal().toRFC2822(),
                group: feature.properties.featureType,
                type: 'box'
            });
        });

        return dataBuffer;
    }
    
    static getMarkerOptions(feature) {

        let coords = CoordinatesHelper.latLngtoDecimalDegreesMinutesString(feature.geometry.coordinates[1], feature.geometry.coordinates[0])
        return {
            icon: L.divIcon({
                html:
                    `<svg id="Layer_2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48.94 33.78">
                    <defs>
                        <style>
                            .cls-1{fill:#1d1d1b;}
                        </style>
                    </defs>
                    <g id="Icon">
                        <g>
                            <path class="cls-1" d="m40.17,0H8.77C3.93,0,0,3.93,0,8.77v7.15c0,4.84,3.93,8.77,8.77,8.77h8.77s0,0,0,0h0s0,0,0,0h0s6.38,0,6.38,0h0s14.51,7.41,14.51,7.41l3.29,1.68v-9.22s0,0,0,0h0c4.09-.75,7.2-4.33,7.2-8.63v-7.15c0-4.84-3.93-8.77-8.77-8.77Zm5.46,15.93c0,2.48-1.67,4.58-3.94,5.24.02,0,.03,0,.05,0h0s-.03,0-.04,0c-.48.14-.99.22-1.52.22h-1.23l-.49.03v6.96l-13.34-6.8-.18-.09c-.06-.03-.13-.05-.22-.07-.09-.02-.17-.03-.23-.03h0s-.28,0-.28,0h-3.02s-5.84,0-5.84,0h0s-6.57,0-6.57,0c-3.01,0-5.46-2.45-5.46-5.46v-7.15c0-3.01,2.45-5.46,5.46-5.46h31.39c3.01,0,5.46,2.45,5.46,5.46v7.15Z"/><path class="cls-1" d="m13.33,9.77c-1.41,0-2.56,1.15-2.56,2.56s1.15,2.56,2.56,2.56,2.56-1.15,2.56-2.56-1.15-2.56-2.56-2.56Z"/><path class="cls-1" d="m24.44,9.77c-1.41,0-2.56,1.15-2.56,2.56s1.15,2.56,2.56,2.56,2.56-1.15,2.56-2.56-1.15-2.56-2.56-2.56Z"/><path class="cls-1" d="m35.56,9.77c-1.41,0-2.56,1.15-2.56,2.56s1.15,2.56,2.56,2.56,2.56-1.15,2.56-2.56-1.15-2.56-2.56-2.56Z"/>
                        </g>
                    </g>
                </svg>
                `,
                //shadowUrl: 'leaf-shadow.png',
                className: "rotated-marker",
                iconSize: [20, 40], // size of the icon
                //shadowSize:   [50, 64], // size of the shadow
                iconAnchor: [10, 10], // point of the icon which will correspond to marker's location
                //shadowAnchor: [4, 62],  // the same for the shadow
                popupAnchor: [0, -7] // point from which the popup should open relative to the iconAnchor
            }),
            popupContent: `<b>${feature.properties.callType} - ${DateTime.fromMillis(feature.properties.time[0], { zone: 'utc'}).toLocal().toLocaleString(DateTime.DATETIME_SHORT)}</b><br>
                           <b>Description:</b> ${feature.properties.description}<br>
                           <b>Communication Type:</b> ${feature.properties.communicationType}<br>
                           <b>Call Type:</b> ${feature.properties.callType}<br>
                           <b>Logged By:</b> <a href="https://www.marinetraffic.com/en/ais/details/ships/${feature.properties.loggedByMmsi}" target="_blank">${feature.properties.loggedByMmsi}</a><br>
                           <b>Date:</b> ${DateTime.fromMillis(feature.properties.time[0], { zone: 'utc' }).toLocal().toLocaleString(DateTime.DATETIME_SHORT)}<br>
                           <b>Coordinates:</b>
                           <br>
                           <ul>
                                <li>
                                    <b>Latitude:</b> ${coords.lat}
                                </li>
                                <li>
                                    <b>Longitude:</b> ${coords.lng}
                                </li>
                            </ul>`,
            tooltipContent: ` ${feature.properties.callType}`
        };    
    }
}